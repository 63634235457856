import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK= theme.palette.primary.dark;
 
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500 500">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
		<g viewBox="0 0 980.000000 980.000000" >


		<path fill="#020201" opacity="1.000000" stroke="none" 
	d="
M286.000000,501.000000 
	C190.666687,501.000000 95.833382,501.000000 1.000051,501.000000 
	C1.000034,334.333405 1.000034,167.666794 1.000017,1.000140 
	C167.666565,1.000094 334.333130,1.000094 500.999756,1.000047 
	C500.999847,167.666519 500.999847,334.333038 500.999939,500.999786 
	C429.500000,501.000000 358.000000,501.000000 286.000000,501.000000 
M231.073135,385.161224 
	C230.707001,385.114929 230.340851,385.068604 230.076584,384.397186 
	C229.978622,383.475037 230.234131,382.213013 229.726349,381.684845 
	C225.920349,377.726318 221.963699,373.912628 217.781937,369.579315 
	C217.507874,369.342102 217.233810,369.104858 216.669907,368.318054 
	C215.443039,367.529236 214.216171,366.740448 212.988739,366.014282 
	C212.988739,366.014282 213.050690,366.004791 212.745773,365.441681 
	C212.153442,365.281372 211.561111,365.121033 210.982544,365.027161 
	C210.982544,365.027161 211.048813,365.012360 210.835770,364.439545 
	C210.257401,364.351166 209.679016,364.262756 208.836670,363.629608 
	C205.756836,361.673798 202.895706,358.630188 199.557602,357.959076 
	C187.926422,355.620728 177.399063,348.843384 164.666580,350.555450 
	C155.812820,351.745972 146.679581,350.857880 137.385620,350.339386 
	C136.571564,350.178162 135.757523,350.016968 134.406616,349.494690 
	C131.317596,348.975769 128.228577,348.456848 124.484184,347.717804 
	C119.673218,343.805237 114.862259,339.892639 109.457115,335.607544 
	C108.390396,334.062225 107.323669,332.516907 106.346649,330.766388 
	C106.346649,330.766388 105.984901,330.658020 105.579445,330.043823 
	C101.531921,324.399628 97.484398,318.755463 93.173981,312.508911 
	C91.748909,310.320404 90.323837,308.131927 88.956367,305.188263 
	C79.828293,285.873871 74.289482,265.676697 74.067680,243.393875 
	C74.017685,242.590256 73.967682,241.786621 74.095924,240.195908 
	C74.987350,231.751816 75.878777,223.307724 76.770195,214.863632 
	C76.325996,214.789627 75.881790,214.715607 75.437592,214.641602 
	C74.166847,223.413147 72.896111,232.184692 71.064957,241.737106 
	C71.104362,245.828125 71.143761,249.919159 71.001358,254.928421 
	C71.309433,273.964142 74.024994,292.566467 80.996078,311.240967 
	C84.043503,318.161133 87.090935,325.081268 90.179527,332.714661 
	C95.740883,341.587280 101.302246,350.459869 106.999649,359.246033 
	C106.999649,359.246033 106.820869,359.186737 107.249916,359.526031 
	C107.403152,359.624817 107.556396,359.723633 107.736343,360.318817 
	C108.182823,360.785675 108.629303,361.252533 109.310303,362.229187 
	C109.586594,362.477997 109.862892,362.726837 110.254280,363.499756 
	C110.668549,363.815765 111.082817,364.131775 111.508690,364.506622 
	C111.508690,364.506622 111.568558,364.507019 111.797729,365.132141 
	C114.887489,368.400574 117.977242,371.669006 121.384285,375.525269 
	C124.613609,377.989563 127.842934,380.453888 131.091187,383.648041 
	C132.401230,387.335236 135.189285,388.916321 139.245407,389.613495 
	C140.192001,390.958801 140.138931,394.167358 143.612122,391.859863 
	C143.743256,391.964630 143.874390,392.069427 143.967331,392.818817 
	C143.708878,395.457214 145.172699,395.513977 147.015717,395.000458 
	C147.015717,395.000458 147.093628,394.901611 147.034393,395.448761 
	C147.760986,395.569031 148.487579,395.689270 149.601456,395.887573 
	C149.601456,395.887573 149.977005,396.010254 149.977371,396.738342 
	C150.190903,397.663391 150.095947,399.066681 150.668106,399.435730 
	C154.313782,401.787415 158.106445,403.911163 162.305328,406.516144 
	C162.881271,406.687164 163.457214,406.858185 164.737549,407.025604 
	C166.143997,407.635193 167.550430,408.244812 169.073120,409.572113 
	C174.015320,411.690521 178.957520,413.808929 184.592316,416.141174 
	C187.956375,417.489441 191.226486,419.196503 194.705078,420.107361 
	C200.942261,421.740570 207.266373,423.124939 213.625229,424.188202 
	C220.710678,425.372894 227.875381,426.083557 235.702362,427.032837 
	C236.166901,427.055237 236.631454,427.077637 237.487961,427.128601 
	C237.487961,427.128601 237.880844,427.118866 238.802170,427.114960 
	C242.579483,426.465546 246.356781,425.816162 250.876389,425.085876 
	C256.277985,424.996582 261.679596,424.907288 267.878632,424.838043 
	C268.643494,424.638214 269.408356,424.438385 270.919861,424.370941 
	C276.271973,422.919281 281.624084,421.467621 287.822632,420.061859 
	C296.191376,416.972565 304.560089,413.883301 313.638336,410.636749 
	C320.095154,407.017731 326.551941,403.398712 333.604797,399.522125 
	C336.087708,397.695038 338.570618,395.867950 341.688293,393.817810 
	C350.044159,385.843719 358.794434,378.233551 366.610809,369.761749 
	C371.454651,364.511780 374.880432,357.953461 379.341827,351.481171 
	C380.406464,349.758453 381.471130,348.035736 382.535767,346.313019 
	C382.508728,346.303467 382.481689,346.293945 382.454681,346.284424 
	C381.298584,348.197418 380.142487,350.110443 378.507263,352.503204 
	C377.555786,353.363861 376.401184,354.082153 375.683411,355.106537 
	C365.632233,369.451660 353.931610,382.237885 339.445404,393.266388 
	C336.756927,395.105713 334.068481,396.945038 330.856323,398.960999 
	C330.232483,399.354279 329.608673,399.747559 328.551086,400.321564 
	C328.277588,400.474670 328.018127,400.648712 327.057373,400.994385 
	C320.374725,403.731995 313.692078,406.469635 306.332428,409.264954 
	C305.929169,409.456970 305.525940,409.648987 304.570709,409.786926 
	C304.043732,409.935150 303.516785,410.083344 302.250946,410.269745 
	C301.785889,410.428192 301.320831,410.586639 300.004059,410.633057 
	C292.347076,410.748077 284.690125,410.863098 276.355743,410.792603 
	C274.841583,410.218445 273.327423,409.644318 271.457916,408.797028 
	C271.029907,408.752899 270.601929,408.708740 269.497345,408.261902 
	C269.239929,408.210846 268.982483,408.159821 268.256012,407.689545 
	C267.458405,407.444916 266.660797,407.200256 265.394897,406.523315 
	C260.929443,404.336395 256.463959,402.149445 251.682358,399.601746 
	C251.465027,399.356873 251.204041,399.174469 250.612061,398.698303 
	C250.398392,398.478180 250.158676,398.291565 249.508835,397.585632 
	C246.025558,394.666290 242.682999,391.551117 239.002182,388.906738 
	C237.036484,387.494537 234.486969,386.894958 231.902008,385.651398 
	C231.902008,385.651398 231.561462,385.427246 231.073135,385.161224 
M182.288666,214.371887 
	C182.288666,214.371887 182.151672,214.832870 181.748367,214.950409 
	C181.585907,215.312592 181.423447,215.674759 180.805939,216.557297 
	C171.135818,232.629654 172.771332,253.558670 184.777908,266.198212 
	C184.777908,266.198212 184.650238,266.268280 184.795090,266.797943 
	C185.072861,267.021515 185.350647,267.245117 185.659409,267.125061 
	C185.659409,267.125061 185.591187,267.463348 185.920395,267.995728 
	C187.612518,269.406464 189.304642,270.817200 191.375565,272.767792 
	C191.586700,272.829010 191.797821,272.890228 192.265625,273.296570 
	C192.265625,273.296570 192.689743,273.368286 192.816849,273.193604 
	C192.816849,273.193604 192.650208,273.331085 192.929947,273.941071 
	C199.126633,276.209869 205.161530,279.387543 211.559372,280.526489 
	C220.341431,282.089935 229.422928,281.188721 236.814438,275.547455 
	C240.761948,272.534698 244.208496,271.289032 248.884521,273.387726 
	C248.884521,271.556000 248.884521,269.787201 249.017044,267.547974 
	C249.017044,267.547974 249.113541,267.068848 249.490891,266.857849 
	C249.474762,266.118134 249.458633,265.378387 249.501999,263.749268 
	C249.495468,255.538116 249.488937,247.326950 249.776245,238.677856 
	C249.668839,238.359467 249.561417,238.041061 249.098862,236.984787 
	C244.810074,236.787384 240.521286,236.589981 235.539719,236.087845 
	C234.316833,236.210342 233.093948,236.332855 231.708694,236.471619 
	C234.296722,238.667130 236.248001,240.322464 238.312790,242.636505 
	C238.447433,243.061279 238.582077,243.486038 238.565018,244.529877 
	C238.687424,245.367081 238.809830,246.204269 238.954163,247.984314 
	C238.954163,256.642365 238.954163,265.300415 238.954163,273.693176 
	C237.207916,274.052673 235.700089,274.526367 234.164368,274.648071 
	C229.900208,274.985992 225.567474,275.743591 221.363922,275.320740 
	C208.700165,274.046722 199.544281,266.746063 191.968002,257.019318 
	C191.968002,257.019318 191.903992,257.043640 191.742523,256.326294 
	C184.261917,242.220139 183.082916,227.771851 189.763260,212.553131 
	C189.784836,212.379623 189.806412,212.206100 190.286896,211.776199 
	C190.831223,210.844238 191.375565,209.912292 192.450439,208.874023 
	C192.577515,208.560196 192.704605,208.246368 193.361786,207.490387 
	C199.975510,201.228424 207.851120,198.704239 217.529510,199.975403 
	C219.018875,200.277237 220.508224,200.579071 222.545502,201.058701 
	C222.926208,201.080460 223.306931,201.102219 224.171722,201.621811 
	C232.366348,205.535263 238.640930,211.693375 243.926300,219.159271 
	C250.061447,208.137192 248.167740,203.008194 237.499542,201.687897 
	C233.622864,200.469131 229.746201,199.250381 225.478119,197.850922 
	C224.665375,197.721405 223.852646,197.591888 222.533203,196.796890 
	C220.621017,196.729492 218.708817,196.662094 215.960556,196.399567 
	C204.588608,196.766510 194.714233,200.534729 186.376602,209.344360 
	C186.287888,209.534515 186.199173,209.724670 185.601151,210.103180 
	C184.805725,211.135895 184.010284,212.168594 182.859802,213.376465 
	C182.638000,213.580551 182.580933,213.823318 182.288666,214.371887 
M261.860321,329.166779 
	C261.860321,329.166779 261.780884,329.204956 262.196289,329.744080 
	C263.467957,330.219940 264.739624,330.695801 266.169220,331.354980 
	C266.169220,331.354980 266.364777,331.212524 266.915375,331.710693 
	C274.080933,332.823914 281.242340,334.794525 288.413116,334.829254 
	C297.019257,334.870941 305.570862,333.102112 312.087769,325.824585 
	C312.087769,325.824585 312.241150,325.494019 312.829926,325.219299 
	C313.450684,324.112915 314.071472,323.006561 314.909119,321.782593 
	C314.909119,321.782593 314.849792,321.543121 315.382690,321.157806 
	C316.012665,318.423645 316.642670,315.689453 317.561249,312.259583 
	C317.407928,310.534546 317.254608,308.809479 317.320740,306.481628 
	C315.889923,303.305237 314.459137,300.128845 312.997925,296.996643 
	C312.997925,296.996643 313.047455,296.976257 312.895996,296.319275 
	C307.146240,289.838348 299.151550,288.110321 291.296997,285.982788 
	C283.646454,283.910492 275.717438,282.367981 268.537201,279.181274 
	C259.152130,275.016052 258.626465,266.288452 266.684753,259.902802 
	C274.268768,253.892990 282.889343,253.623169 291.723724,256.170105 
	C300.893250,258.813660 306.209259,266.284668 312.078949,273.566833 
	C312.677460,273.400116 313.275940,273.233398 314.300110,272.523773 
	C314.789215,270.996063 315.278351,269.468353 315.973663,267.795746 
	C315.973663,267.795746 315.895142,267.556274 316.156464,267.353394 
	C316.104950,266.933899 316.053436,266.514404 316.158325,265.521667 
	C316.223541,264.632751 316.667145,263.204773 316.292847,262.942017 
	C313.781616,261.179352 311.096436,259.664490 308.102844,257.548737 
	C299.935150,255.809860 291.827637,253.097549 283.583954,252.587784 
	C274.087616,252.000580 264.892761,254.531647 257.711639,261.721497 
	C257.711639,261.721497 257.647339,261.683990 257.048157,261.964142 
	C248.513977,271.012207 250.280670,283.308716 261.241516,288.932159 
	C265.625092,291.181152 270.446472,292.659637 275.181976,294.121429 
	C283.396881,296.657318 292.013916,298.162354 299.882141,301.476837 
	C308.577942,305.139954 311.026825,311.552460 309.144379,321.140015 
	C309.144379,321.140015 309.092957,321.389832 308.774536,321.942108 
	C305.798462,324.673462 303.250671,328.309235 299.763214,329.960663 
	C290.578094,334.310059 281.132446,333.606873 271.414734,328.800964 
	C262.093018,323.306458 256.666351,314.528076 252.575974,304.623108 
	C252.093643,304.872284 251.611313,305.121460 251.128983,305.370605 
	C251.128983,310.248383 251.128983,315.126160 250.974869,320.546692 
	C253.325760,322.776611 255.676651,325.006561 258.545319,327.727570 
	C258.772888,327.796906 259.000427,327.866272 259.604614,328.432404 
	C260.365997,328.704987 261.127380,328.977600 261.860321,329.166779 
M394.571960,319.494568 
	C390.978119,326.994873 387.384308,334.495178 383.790497,341.995483 
	C384.497131,342.291779 385.203796,342.588074 385.910431,342.884369 
	C388.941650,334.923492 391.972839,326.962646 394.959381,318.860321 
	C394.959381,318.860321 395.200928,318.910492 395.200928,318.910492 
	C395.200928,318.910492 395.000916,318.990356 394.571960,319.494568 
M203.626984,118.870949 
	C204.925888,118.302429 206.224792,117.733917 209.057709,116.493965 
	C206.259369,115.222679 204.806183,114.562485 202.723724,113.631821 
	C202.512009,113.546349 202.300308,113.460884 202.086304,112.859329 
	C202.026382,112.520996 201.966461,112.182655 201.958252,111.349709 
	C199.037460,107.472260 194.967651,106.444397 191.449234,108.695572 
	C187.995117,110.905609 186.660339,115.685020 188.648773,119.104874 
	C191.733688,124.410599 199.044998,124.943314 203.626984,118.870949 
M153.303909,113.321030 
	C151.333511,114.010498 149.023819,114.256470 147.448166,115.461922 
	C137.724136,122.901260 127.672165,130.022827 118.705711,138.306976 
	C111.339767,145.112381 105.328728,153.384293 98.717148,161.006195 
	C99.239769,161.445587 99.762398,161.884964 100.285019,162.324341 
	C101.368637,161.049667 102.423866,159.749542 103.540398,158.504395 
	C111.584732,149.533218 119.008385,139.875900 127.883293,131.817749 
	C135.789246,124.639381 145.305588,119.234619 153.768051,112.835503 
	C153.947296,112.655556 154.126556,112.475616 154.305801,112.295662 
	C154.406845,112.400902 154.507889,112.506142 154.608932,112.611374 
	C154.423157,112.772079 154.237381,112.932777 153.303909,113.321030 
M366.204376,158.623108 
	C377.596680,172.711960 385.814240,188.700943 393.481079,205.026489 
	C387.659088,187.285141 379.080353,171.103470 366.784668,156.238602 
	C349.417999,135.644211 329.303955,118.633156 304.193604,108.244125 
	C306.806549,110.619690 309.965607,112.156670 313.030212,113.863327 
	C333.450073,125.235008 351.264252,139.742386 366.204376,158.623108 
M250.542007,122.942390 
	C256.704987,128.326675 264.937714,128.260483 271.054382,122.972153 
	C270.212341,121.226204 268.431732,119.318367 268.768250,117.920052 
	C270.151062,112.174370 266.748993,108.955231 264.558563,106.678307 
	C260.692474,106.678307 258.130615,106.360535 255.702240,106.792725 
	C254.146210,107.069649 251.755814,108.241760 251.523300,109.377708 
	C250.650711,113.640732 250.511368,118.053825 250.542007,122.942390 
M394.899139,240.820740 
	C394.642853,238.266052 394.386536,235.711380 394.080719,232.663071 
	C388.694305,234.044296 383.970093,235.255707 378.765228,236.590378 
	C379.520081,239.192703 379.718536,241.682220 380.938721,243.477173 
	C381.818024,244.770660 384.332458,246.099884 385.636566,245.727707 
	C388.719849,244.847794 391.516266,242.962753 394.899139,240.820740 
M293.441681,133.171265 
	C293.890656,131.812454 294.211731,130.393280 294.817932,129.108658 
	C295.779053,127.071846 298.297516,124.404289 297.711578,123.293076 
	C296.562683,121.114128 293.840363,119.610092 291.480042,118.382721 
	C291.103455,118.186913 289.017792,120.755608 288.094482,122.272781 
	C287.066833,123.961388 286.296631,125.859184 285.733002,127.760376 
	C285.311401,129.182373 285.395111,130.754181 285.253784,132.259277 
	C286.636566,131.863602 288.019348,131.467941 289.065826,131.168503 
	C290.163086,132.881424 291.211395,134.517960 292.752045,136.923035 
	C293.102173,135.168274 293.229523,134.529968 293.441681,133.171265 
M382.579163,214.106461 
	C383.713165,212.960052 384.847168,211.813644 385.981171,210.667252 
	C384.415039,210.731293 382.795135,210.567673 381.297455,210.921478 
	C379.704285,211.297852 378.267792,212.517197 376.685455,212.700516 
	C375.507660,212.836990 374.206482,211.909073 372.960266,211.455185 
	C373.424957,210.194763 373.526794,208.436356 374.430237,207.778137 
	C376.277435,206.432327 378.647430,205.825058 380.606964,204.601196 
	C381.307190,204.163849 381.443756,202.824051 381.837616,201.896194 
	C380.959229,201.733505 379.930420,201.195282 379.229645,201.475983 
	C376.767944,202.462097 374.344696,203.600250 372.044617,204.920670 
	C368.478027,206.968170 368.066620,209.955185 369.984680,213.404297 
	C372.437042,217.814240 374.560883,217.983337 382.579163,214.106461 
M359.071320,185.236176 
	C355.968872,186.102936 355.629242,184.287674 355.595093,181.864288 
	C355.578217,180.667236 355.012268,179.477936 354.540955,177.707397 
	C350.870148,181.070160 351.814606,184.061798 353.898224,186.216003 
	C355.402985,187.771729 358.552368,189.506363 360.125031,188.915787 
	C366.424042,186.550293 360.631409,181.739700 361.983124,177.928284 
	C363.013123,179.005493 363.408630,180.280930 363.990540,181.464752 
	C364.388916,182.275284 364.948669,183.082474 365.642242,183.630554 
	C365.884094,183.821716 367.250641,183.208481 367.240875,183.003571 
	C367.144653,180.977097 367.567719,178.335709 366.464386,177.095856 
	C365.066833,175.525269 362.386597,175.096130 360.259521,174.174713 
	C359.483276,175.973450 358.289520,177.721268 358.061920,179.586945 
	C357.877258,181.100586 358.852936,182.755783 359.071320,185.236176 
M318.928802,132.579407 
	C316.933472,137.215042 318.825592,143.051575 314.506622,147.012756 
	C314.371307,147.136856 314.853851,148.315140 315.300568,148.585938 
	C315.743591,148.854477 316.708038,148.845734 317.096741,148.530563 
	C320.903595,145.443954 324.637787,142.267761 329.288666,138.365219 
	C326.295929,138.656525 323.757385,138.903625 321.744263,139.099579 
	C320.998810,136.931641 320.292633,134.877899 318.928802,132.579407 
M223.879028,107.239388 
	C223.879028,111.660057 223.879028,116.080719 223.879028,121.440987 
	C227.208008,120.653748 229.898056,120.017601 232.588104,119.381454 
	C230.922150,118.428757 229.256210,117.476059 227.174103,116.285378 
	C227.174103,112.961426 227.174103,109.193146 227.174103,104.271355 
	C225.638077,105.270866 224.776245,105.831665 223.879028,107.239388 
M97.460045,167.083176 
	C97.621437,166.511826 97.782829,165.940460 97.944221,165.369110 
	C97.509468,165.165039 97.074715,164.960983 96.639961,164.756912 
	C92.875450,171.845657 89.110939,178.934387 85.346436,186.023132 
	C85.688995,186.205444 86.031555,186.387741 86.374115,186.570053 
	C89.954247,180.288361 93.534386,174.006683 97.460045,167.083176 
M402.881622,269.110382 
	C402.959717,270.778351 403.037781,272.446320 403.115875,274.114258 
	C403.575073,274.102661 404.034271,274.091034 404.493469,274.079437 
	C404.493469,267.461090 404.493469,260.842743 404.493469,254.224426 
	C404.235962,254.209641 403.978455,254.194855 403.720947,254.180069 
	C403.444672,258.858887 403.168365,263.537720 402.881622,269.110382 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M137.671188,350.898407 
	C146.679581,350.857880 155.812820,351.745972 164.666580,350.555450 
	C177.399063,348.843384 187.926422,355.620728 199.557602,357.959076 
	C202.895706,358.630188 205.756836,361.673798 209.133911,364.130005 
	C209.970352,364.757721 210.509583,364.885040 211.048813,365.012360 
	C211.048813,365.012360 210.982544,365.027161 211.103867,365.297272 
	C211.833694,365.713196 212.442200,365.858978 213.050690,366.004761 
	C213.050690,366.004791 212.988739,366.014282 212.951416,366.350647 
	C213.395386,367.816437 213.876648,368.945862 214.039062,370.021973 
	C213.505692,369.948242 213.291153,369.927795 212.959595,369.649658 
	C212.397446,369.045410 211.952316,368.698853 211.312607,368.209839 
	C210.828384,367.911194 210.519531,367.820221 210.064392,367.584839 
	C209.703049,367.127930 209.417557,366.978546 208.925354,366.672302 
	C208.245956,366.341553 207.722046,366.265594 206.050812,366.023224 
	C207.522537,366.956085 207.999344,367.258301 208.626282,367.731781 
	C208.998306,368.118652 209.239288,368.310272 209.670532,368.662781 
	C210.149399,368.976715 210.457077,369.105835 210.930069,369.461487 
	C211.568268,369.982208 212.041138,370.276428 212.732498,370.807465 
	C214.690826,372.622650 216.430695,374.200958 217.858856,375.845367 
	C217.338455,375.949707 217.129745,375.987946 216.617035,375.906891 
	C215.531281,375.538025 214.749496,375.288391 213.968292,374.692902 
	C210.023804,371.672272 206.078735,368.997528 202.133667,366.322784 
	C201.874680,366.723663 201.615707,367.124542 201.356735,367.525421 
	C204.988098,369.848572 208.619446,372.171753 212.033142,374.642395 
	C207.941360,373.505981 204.067215,372.222107 199.151062,370.592896 
	C200.242462,372.013977 200.620621,372.506378 200.733109,373.072754 
	C200.129684,373.650024 199.791931,374.153259 199.454163,374.656525 
	C199.709778,374.784607 199.965378,374.912689 200.220978,375.040771 
	C200.480988,374.360931 200.740997,373.681061 201.352737,373.067322 
	C203.464203,374.741119 205.223938,376.348877 206.671875,378.030762 
	C203.615387,378.259644 200.870712,378.414398 198.126053,378.569183 
	C198.421875,378.247040 198.717712,377.924896 199.013550,377.602722 
	C200.320648,379.379608 201.627747,381.156464 202.650909,382.805237 
	C199.848801,381.428253 197.490982,380.603333 196.753082,384.668274 
	C195.614761,384.442596 194.720963,384.256561 193.827179,384.070526 
	C193.765137,384.363007 193.703094,384.655518 193.641052,384.947998 
	C195.444214,385.308960 197.247375,385.669922 199.029694,386.007446 
	C199.008865,385.983978 198.946411,385.977875 199.063782,386.300171 
	C201.164200,387.625610 203.147247,388.628723 205.050888,389.919434 
	C204.484955,390.521057 203.865417,391.211151 203.531693,391.093140 
	C198.061218,389.158356 192.626801,387.121643 187.166214,385.101135 
	C186.549042,387.049011 186.115738,388.416504 185.696121,389.740906 
	C189.101776,393.509491 192.529770,392.701263 195.996277,389.994629 
	C196.000259,390.000000 196.011627,390.008240 195.894318,390.260437 
	C195.594940,390.975189 195.118546,391.664520 195.274353,391.866760 
	C198.276031,395.762817 196.546112,399.497162 195.322815,403.444061 
	C193.097183,402.745911 191.238281,402.162811 189.346222,401.569305 
	C187.531326,403.540405 185.789246,405.432404 183.487717,407.932007 
	C182.889816,405.121307 182.453079,403.068237 182.237808,400.974060 
	C182.741974,400.646729 183.024673,400.360504 183.307373,400.074219 
	C183.178177,400.033234 182.977234,399.918396 182.931213,399.963074 
	C182.599533,400.285339 182.299240,400.639923 181.627167,400.985596 
	C179.506149,400.987427 177.746979,400.987976 175.818298,401.012573 
	C175.516830,401.206451 175.384872,401.376221 175.252914,401.546021 
	C175.504120,401.367523 175.755341,401.189056 176.201340,401.243958 
	C176.487167,402.362030 176.578201,403.246735 176.716766,404.593323 
	C172.760132,403.155823 170.855743,401.427124 173.186859,397.649658 
	C174.890137,398.024567 176.332657,398.342072 177.775177,398.659576 
	C174.358566,396.517395 177.252075,395.093262 179.129944,392.869965 
	C175.761719,393.450592 173.651108,393.814423 170.941757,394.281494 
	C170.229752,393.867493 168.617844,392.930237 166.902649,391.682556 
	C165.884506,390.895264 164.969681,390.418396 163.985535,389.721008 
	C163.370636,389.346191 162.825058,389.191864 162.279465,389.037537 
	C162.227554,389.289337 162.175644,389.541107 162.123734,389.792908 
	C162.724442,389.887970 163.325150,389.983032 164.060471,390.316772 
	C164.459686,391.034485 164.724304,391.513489 164.774048,392.257629 
	C163.351212,394.074585 162.143219,395.626404 160.557663,397.093018 
	C159.441589,396.995575 158.703094,396.983368 157.972260,396.997375 
	C157.979935,397.023621 158.034576,397.025604 158.200455,396.719727 
	C158.257950,396.208679 158.149582,396.003571 157.876190,395.642517 
	C157.477646,395.289398 157.219391,395.133240 156.697189,394.967468 
	C156.458008,394.916901 156.015442,394.709106 156.204681,394.466980 
	C157.433670,393.093872 158.473404,391.962891 160.225403,390.057190 
	C156.696228,390.470947 154.278000,390.754456 151.735016,390.811462 
	C150.442688,390.257141 149.275131,389.929260 148.099457,389.485718 
	C148.091370,389.369995 147.929764,389.203461 148.290634,389.121948 
	C148.088348,388.403168 147.525192,387.765839 147.322449,387.061462 
	C148.044388,386.359222 148.405884,385.724060 149.000229,385.073547 
	C149.233063,385.058197 149.698792,385.028564 150.087906,385.106567 
	C151.372971,384.787933 152.268906,384.391266 153.373718,383.991699 
	C153.582581,383.988770 154.001877,383.979523 154.177277,383.944214 
	C154.588638,383.861389 154.801544,383.763702 155.062378,383.212708 
	C155.088333,382.540527 155.043350,382.271423 155.394501,382.005493 
	C161.180328,382.023499 166.570023,382.038330 172.039032,382.287506 
	C172.191696,383.930969 172.265030,385.340027 172.338364,386.749115 
	C174.227005,385.854065 176.115662,384.959015 178.004303,384.063965 
	C177.773621,383.520660 177.542923,382.977386 177.312225,382.434113 
	C175.558029,382.271210 173.803818,382.108307 171.913025,381.615479 
	C164.943573,376.242004 157.631805,380.837799 150.257996,380.167480 
	C149.293137,380.047455 148.648743,379.992371 148.003510,379.966797 
	C148.002670,379.996368 148.047714,379.958069 147.907074,379.651550 
	C146.515106,378.804016 145.263794,378.263031 144.028610,377.548706 
	C144.044754,377.375366 144.077713,377.028778 144.454147,376.977051 
	C148.634323,374.730835 147.105469,371.811462 146.079269,368.700806 
	C146.823242,367.705139 147.404175,366.946960 148.007233,365.925781 
	C146.356033,365.045929 144.682709,364.429016 143.026184,363.614868 
	C143.042984,363.417633 143.092758,363.024841 143.334991,362.948059 
	C143.380692,362.249786 143.184143,361.628357 142.653717,360.984314 
	C141.875671,360.906769 141.431458,360.851807 141.103760,360.545410 
	C140.798401,359.587921 140.376526,358.881866 139.584167,358.140167 
	C138.475830,358.143555 137.737961,358.182556 137.141937,357.955231 
	C137.822311,357.101776 138.360840,356.514648 138.951187,355.704041 
	C139.003006,355.480560 138.943619,355.026245 139.132416,354.920166 
	C139.562622,354.652283 139.778671,354.461700 140.178589,354.022095 
	C138.859497,353.272919 137.331177,352.744019 135.840424,351.959167 
	C135.903992,351.532776 135.929993,351.362305 136.242188,351.139709 
	C136.909302,351.024567 137.290253,350.961487 137.671188,350.898407 
M157.601852,386.995789 
	C157.440659,387.145355 157.279449,387.294922 157.118256,387.444489 
	C157.437164,387.312531 157.756058,387.180573 158.203430,386.942139 
	C158.203430,386.942139 158.181946,386.712128 158.181946,386.712128 
	C158.181946,386.712128 157.955292,386.933136 157.601852,386.995789 
M177.360870,377.679840 
	C177.490128,377.243927 177.619385,376.808044 177.748627,376.372131 
	C177.560455,376.329926 177.372284,376.287750 177.184113,376.245544 
	C177.110443,376.818634 177.036789,377.391754 176.551834,378.049683 
	C176.327072,378.225281 176.102310,378.400848 175.877548,378.576447 
	C176.047241,378.758850 176.216919,378.941254 176.386612,379.123627 
	C176.602814,378.761353 176.819000,378.399078 177.360870,377.679840 
M189.591705,366.845612 
	C189.308090,366.840363 189.024460,366.835144 188.740860,366.829926 
	C188.784805,367.053375 188.828766,367.276825 188.872711,367.500244 
	C189.256760,367.339264 189.640823,367.178253 190.155762,366.933899 
	C190.155762,366.933899 190.159790,366.745422 190.159790,366.745422 
	C190.159790,366.745422 189.983780,366.977020 189.591705,366.845612 
M150.472763,371.116486 
	C150.647324,371.745361 150.821899,372.374207 151.533905,373.418884 
	C154.074997,373.188721 156.616089,372.958527 159.157196,372.728333 
	C158.996048,372.015106 158.834900,371.301910 158.673752,370.588715 
	C156.117035,371.391266 153.560303,372.193787 151.005753,372.341492 
	C151.014847,371.904938 151.023926,371.468353 151.377594,370.976929 
	C151.506485,370.795319 151.635376,370.613678 151.764267,370.432068 
	C151.503113,370.611206 151.241974,370.790344 150.472763,371.116486 
M150.841156,368.047638 
	C152.944992,368.169983 155.048828,368.292328 157.152679,368.414642 
	C157.067245,367.761139 156.981812,367.107635 156.896378,366.454132 
	C154.577408,366.955688 152.258423,367.457214 149.799255,368.063965 
	C149.799255,368.063965 149.813950,368.303772 149.813950,368.303772 
	C149.813950,368.303772 150.044815,368.065063 150.841156,368.047638 
M185.495773,382.433319 
	C187.034698,382.908844 188.573608,383.384399 190.112534,383.859955 
	C190.172943,383.239136 190.233337,382.618347 190.293747,381.997559 
	C188.529831,381.997559 186.765915,381.997559 185.026077,381.382721 
	C185.238678,380.369110 185.451263,379.355530 185.757431,377.895844 
	C183.903000,378.574341 182.518280,379.080963 179.866501,380.051178 
	C182.428146,381.024628 183.713608,381.513123 185.495773,382.433319 
M181.895538,366.401062 
	C180.803268,365.579590 179.820892,364.347290 178.596497,364.019836 
	C175.812576,363.275299 172.909744,362.975281 170.054001,362.504822 
	C170.774460,367.963196 170.908035,368.056519 176.087936,366.849030 
	C176.359375,367.573486 176.624786,368.281891 176.875046,368.949768 
	C178.639450,367.993408 179.997711,367.257172 181.895538,366.401062 
M189.880066,370.634521 
	C188.578476,370.239136 187.276871,369.843750 185.975281,369.448395 
	C185.914856,370.021484 185.854416,370.594543 185.793991,371.167633 
	C187.131866,371.167633 188.469742,371.167633 189.880066,370.634521 
M193.963455,371.932892 
	C194.882034,372.595673 195.800613,373.258423 196.719193,373.921173 
	C196.909439,373.470856 197.099670,373.020538 197.289917,372.570251 
	C196.302704,372.237793 195.315491,371.905365 193.963455,371.932892 
M154.350174,363.263000 
	C153.667038,363.615540 152.983902,363.968048 152.300766,364.320587 
	C152.532639,364.680969 152.764511,365.041382 152.996384,365.401794 
	C153.616730,364.740601 154.237076,364.079407 154.350174,363.263000 
M168.956848,388.567169 
	C168.957550,388.187836 168.958267,387.808472 168.958984,387.429138 
	C168.559937,387.540619 168.160904,387.652069 167.761856,387.763550 
	C168.010757,388.069031 168.259659,388.374512 168.956848,388.567169 
M157.710892,365.050812 
	C158.405106,365.050812 159.099319,365.050812 159.793518,365.050812 
	C159.761520,364.745392 159.729523,364.440002 159.697525,364.134583 
	C159.063065,364.290405 158.428619,364.446228 157.710892,365.050812 
M201.721436,364.058990 
	C202.359818,364.290985 202.998184,364.522980 203.636566,364.754974 
	C203.706772,364.488434 203.776962,364.221863 203.847168,363.955322 
	C203.051910,363.815399 202.256668,363.675446 201.721436,364.058990 
M148.064636,363.440186 
	C148.005798,363.074951 147.946945,362.709747 147.888092,362.344482 
	C147.609940,362.401367 147.331787,362.458252 147.053635,362.515137 
	C147.252411,362.847351 147.451187,363.179596 148.064636,363.440186 
M173.004852,374.990631 
	C172.623398,374.886810 172.241943,374.782990 171.860489,374.679169 
	C171.856689,374.944000 171.852890,375.208862 171.849091,375.473694 
	C172.240540,375.446716 172.631989,375.419739 173.004852,374.990631 
M194.124161,400.398956 
	C194.144913,400.153534 194.165665,399.908081 194.186417,399.662659 
	C194.047394,399.658875 193.908371,399.655060 193.769348,399.651245 
	C193.778488,399.897644 193.787628,400.144073 194.124161,400.398956 
M152.381271,376.204712 
	C152.225616,376.016174 152.069962,375.827606 151.914307,375.639069 
	C151.813141,375.732239 151.711960,375.825439 151.610794,375.918610 
	C151.788940,376.086945 151.967072,376.255249 152.381271,376.204712 
M163.872040,375.588837 
	C163.851227,375.838440 163.830414,376.088074 163.809601,376.337677 
	C163.950989,376.341370 164.092361,376.345032 164.233749,376.348694 
	C164.224258,376.098114 164.214783,375.847504 163.872040,375.588837 
M163.896103,372.601410 
	C163.876038,372.843658 163.855988,373.085876 163.835922,373.328094 
	C163.972931,373.331360 164.109924,373.334625 164.246933,373.337921 
	C164.237717,373.094971 164.228485,372.852020 163.896103,372.601410 
M188.460892,393.917328 
	C188.388580,394.156067 188.316269,394.394806 188.243958,394.633545 
	C188.612625,394.517578 188.981293,394.401611 189.349960,394.285645 
	C189.166306,394.121582 188.982635,393.957489 188.460892,393.917328 
M182.865677,387.603088 
	C182.845154,387.843506 182.824631,388.083893 182.804092,388.324341 
	C182.940323,388.328491 183.076553,388.332672 183.212784,388.336823 
	C183.204117,388.095184 183.195435,387.853546 182.865677,387.603088 
M194.636154,368.792572 
	C194.790909,368.980225 194.945663,369.167877 195.100418,369.355499 
	C195.200989,369.262421 195.301544,369.169342 195.402130,369.076294 
	C195.225037,368.909119 195.047928,368.741943 194.636154,368.792572 
M186.076080,400.842682 
	C186.100830,401.067169 186.125595,401.291626 186.150330,401.516113 
	C186.220261,401.286926 186.290192,401.057709 186.076080,400.842682 
M165.070053,365.475159 
	C165.328552,365.530914 165.587036,365.586700 165.845535,365.642456 
	C165.645905,365.512299 165.446274,365.382141 165.070053,365.475159 
M166.231430,363.262512 
	C166.369995,363.433533 166.508560,363.604523 166.647110,363.775543 
	C166.601807,363.580505 166.556503,363.385498 166.231430,363.262512 
M168.910034,374.525604 
	C168.659546,374.471283 168.409058,374.416962 168.158569,374.362640 
	C168.351669,374.488342 168.544754,374.614044 168.910034,374.525604 
M194.691086,381.486450 
	C194.809891,381.307068 194.928696,381.127686 195.047516,380.948303 
	C194.863983,381.060455 194.680450,381.172577 194.691086,381.486450 
M162.812317,386.313080 
	C163.046631,386.261688 163.280945,386.210297 163.515244,386.158875 
	C163.294449,386.115784 163.073654,386.072662 162.812317,386.313080 
M168.521179,384.008026 
	C168.283356,383.906494 168.045532,383.804932 167.807709,383.703400 
	C167.973526,383.868988 168.139343,384.034546 168.521179,384.008026 
M186.087097,396.386780 
	C186.087097,396.386780 185.852112,396.551697 185.852112,396.551697 
	C185.852112,396.551697 186.156219,396.531036 186.087097,396.386780 
M187.910095,398.365662 
	C187.910095,398.365662 187.984436,398.624817 187.984436,398.624817 
	C187.984436,398.624817 188.068558,398.368073 187.910095,398.365662 
M182.165359,377.515808 
	C182.165359,377.515808 181.875793,377.422333 181.875793,377.422333 
	C181.875793,377.422333 182.059616,377.634888 182.165359,377.515808 
M153.471878,386.497528 
	C153.471878,386.497528 153.513748,386.480804 153.471878,386.497528 
M205.487671,365.804321 
	C205.603058,365.710602 205.718460,365.616882 205.833847,365.523163 
	C205.734116,365.413086 205.640320,365.223236 205.532990,365.215271 
	C205.424988,365.207214 205.303528,365.380066 205.487671,365.804321 
M158.495529,362.532776 
	C158.495529,362.532776 158.475922,362.485565 158.495529,362.532776 
M180.469345,394.498138 
	C180.469345,394.498138 180.515381,394.479584 180.469345,394.498138 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M160.935242,397.178223 
	C162.143219,395.626404 163.351212,394.074585 164.780273,392.261810 
	C165.001343,392.000854 165.011154,392.007446 165.131882,392.212280 
	C165.832458,392.280457 166.412308,392.143860 166.995575,392.004028 
	C166.999008,392.000763 167.005936,391.992981 167.005951,391.992981 
	C168.617844,392.930237 170.229752,393.867493 170.941757,394.281494 
	C173.651108,393.814423 175.761719,393.450592 179.129944,392.869965 
	C177.252075,395.093262 174.358566,396.517395 177.775177,398.659576 
	C176.332657,398.342072 174.890137,398.024567 173.186859,397.649658 
	C170.855743,401.427124 172.760132,403.155823 176.716766,404.593323 
	C176.578201,403.246735 176.487167,402.362030 176.197632,401.238220 
	C175.999146,400.999146 175.987793,400.988525 175.987793,400.988525 
	C177.746979,400.987976 179.506149,400.987427 181.633194,400.993774 
	C182.001053,401.000671 182.016342,401.015167 182.016342,401.015167 
	C182.453079,403.068237 182.889816,405.121307 183.487717,407.932007 
	C185.789246,405.432404 187.531326,403.540405 189.346222,401.569305 
	C191.238281,402.162811 193.097183,402.745911 195.322815,403.444061 
	C196.546112,399.497162 198.276031,395.762817 195.274353,391.866760 
	C195.118546,391.664520 195.594940,390.975189 195.962128,390.222351 
	C196.147247,389.932037 196.157242,389.750031 196.157242,389.750031 
	C196.157242,389.750031 195.992310,389.989258 195.992310,389.989258 
	C192.529770,392.701263 189.101776,393.509491 185.696121,389.740906 
	C186.115738,388.416504 186.549042,387.049011 187.166214,385.101135 
	C192.626801,387.121643 198.061218,389.158356 203.531693,391.093140 
	C203.865417,391.211151 204.484955,390.521057 205.363632,390.007141 
	C206.365326,390.029663 206.974884,390.251984 207.849792,390.667297 
	C209.725998,391.596436 211.336884,392.332581 213.115570,393.285034 
	C213.978073,393.701996 214.672775,393.902588 215.367462,394.103180 
	C215.420227,393.916840 215.472977,393.730530 215.525726,393.544189 
	C214.880280,393.160980 214.234818,392.777740 213.316284,392.192871 
	C211.395172,391.291992 209.747147,390.592712 207.922333,389.679230 
	C207.357742,389.071991 206.969940,388.678955 206.738235,388.118042 
	C208.086685,386.892151 209.279037,385.834167 210.717529,384.826477 
	C211.293564,384.935425 211.623459,384.994049 212.125732,385.310547 
	C213.198242,386.046631 214.098373,386.524841 215.151917,387.244324 
	C215.822952,387.709229 216.340576,387.932922 216.963242,388.390259 
	C217.376114,388.751617 217.683945,388.879303 218.163635,389.257416 
	C219.197800,390.034576 220.060089,390.561371 221.030899,391.329620 
	C221.420685,391.784912 221.701935,391.998718 222.202011,392.339417 
	C222.957413,391.988434 223.494003,391.510529 224.337585,391.026886 
	C225.053726,391.006683 225.462891,390.992249 225.909515,391.209564 
	C225.947006,391.441315 226.052811,391.898743 225.984833,392.266296 
	C226.071655,394.509979 225.849701,396.522186 226.466888,398.231384 
	C227.470932,401.011932 226.804443,402.296600 223.911758,402.571045 
	C223.723053,402.588928 223.580399,403.092438 223.396301,403.403931 
	C224.067032,404.526733 224.739792,405.652893 225.412552,406.779053 
	C225.098831,407.185608 224.785095,407.592194 224.471359,407.998749 
	C222.686005,406.887146 221.006149,405.502930 219.079193,404.757141 
	C217.734497,404.236694 216.051346,404.590668 214.519379,404.554047 
	C215.184021,405.803650 215.764999,407.108185 216.538345,408.286407 
	C217.224747,409.332153 218.124756,410.237671 218.555420,411.145630 
	C216.456024,410.728699 214.731720,410.371246 212.769775,409.770111 
	C211.481918,409.184174 210.431671,408.841919 209.126465,408.315918 
	C207.913574,407.723389 206.955627,407.314606 205.998505,406.499329 
	C205.066162,404.265350 204.373337,401.151398 203.141693,400.921295 
	C201.429260,400.601379 199.313004,402.442993 197.332809,403.376709 
	C199.729614,404.925446 201.384308,405.994690 203.251526,407.342651 
	C205.301605,408.092773 207.139145,408.564148 209.227631,409.299042 
	C210.675369,409.686249 211.872192,409.809937 213.047424,410.234131 
	C213.394699,411.004578 213.763535,411.474548 213.791840,412.097046 
	C211.918732,413.318665 210.497284,414.633484 208.823532,415.390289 
	C207.010864,416.209900 204.888428,417.140137 203.031067,416.920929 
	C196.600143,416.161896 190.704758,411.771759 183.480698,414.070068 
	C182.613113,413.749420 182.057373,413.472565 181.819519,413.151184 
	C182.797577,412.771454 183.457764,412.436218 184.117935,412.100983 
	C182.928650,411.412689 181.739365,410.724396 179.519196,409.439484 
	C180.405655,411.562042 180.709824,412.290344 180.644012,412.972107 
	C176.501663,411.568481 172.729279,410.211456 168.956879,408.854401 
	C167.550430,408.244812 166.143997,407.635193 164.267288,406.810791 
	C163.148254,406.433990 162.499481,406.271973 161.850708,406.109985 
	C158.106445,403.911163 154.313782,401.787415 150.668106,399.435730 
	C150.095947,399.066681 150.190903,397.663391 150.301422,396.175568 
	C152.096405,395.419373 153.567368,395.225952 155.020172,395.019043 
	C155.002029,395.005524 154.965240,394.979218 155.012756,395.339386 
	C155.716507,395.810425 156.372742,395.921326 157.015045,396.017395 
	C157.001099,396.002594 156.972687,395.973572 156.908173,396.294769 
	C157.240631,396.752502 157.637604,396.889038 158.034576,397.025604 
	C158.034576,397.025604 157.979935,397.023621 158.103607,397.283020 
	C158.986206,397.887238 159.745132,398.232025 160.701294,398.727661 
	C161.220657,398.962494 161.542755,399.046478 162.062897,399.363892 
	C163.009781,399.916168 163.758636,400.234985 164.704071,400.710449 
	C165.224136,400.952667 165.547638,401.038208 166.109161,401.359009 
	C167.619400,402.078705 168.891663,402.563202 170.163910,403.047668 
	C170.275192,402.792664 170.386490,402.537659 170.497772,402.282654 
	C169.159470,401.624603 167.821167,400.966583 166.298676,400.170410 
	C165.831848,399.886902 165.531860,399.804321 165.002045,399.560181 
	C164.022369,399.042145 163.255234,398.748474 162.289185,398.300781 
	C161.764908,398.061615 161.439514,397.976471 161.063751,397.714478 
	C161.013351,397.537628 160.935242,397.178223 160.935242,397.178223 
M208.506897,393.899933 
	C208.725510,394.426697 208.944138,394.953491 209.162750,395.480255 
	C209.497864,395.288239 209.832977,395.096191 210.168091,394.904144 
	C209.753860,394.524414 209.339645,394.144714 208.506897,393.899933 
M198.409668,393.038544 
	C198.753250,392.995026 199.096832,392.951508 199.440414,392.907959 
	C199.344696,392.644348 199.248978,392.380707 199.153275,392.117126 
	C198.862015,392.294556 198.570770,392.471985 198.409668,393.038544 
M177.386856,409.210175 
	C177.223358,409.012939 177.059860,408.815704 176.896347,408.618469 
	C176.790329,408.716339 176.684311,408.814209 176.578308,408.912079 
	C176.765396,409.087646 176.952469,409.263184 177.386856,409.210175 
M171.051620,406.307678 
	C170.765228,406.336670 170.478836,406.365662 170.192444,406.394653 
	C170.298248,406.630219 170.404068,406.865814 170.509888,407.101379 
	C170.782547,406.916901 171.055191,406.732422 171.051620,406.307678 
M196.083801,406.620117 
	C196.083801,406.620117 196.015594,406.380890 196.015594,406.380890 
	C196.015594,406.380890 195.937119,406.617401 196.083801,406.620117 
M213.528290,395.504333 
	C213.528290,395.504333 213.486298,395.521362 213.528290,395.504333 
M190.495132,390.525330 
	C190.495132,390.525330 190.478653,390.482330 190.495132,390.525330 
z"/>
<path fill="#EAC461" opacity="1.000000" stroke="none" 
	d="
M312.241150,325.494019 
	C312.241150,325.494019 312.087769,325.824585 311.575256,325.855225 
	C310.406128,324.387207 309.749542,322.888519 309.092957,321.389832 
	C309.092957,321.389832 309.144379,321.140015 309.566925,320.932251 
	C313.559967,314.936188 311.347809,309.585327 307.546936,305.432159 
	C302.060272,299.436890 294.597534,296.683655 286.603729,295.015930 
	C279.035431,293.436920 271.532013,291.337646 264.209503,288.850586 
	C257.287140,286.499420 254.050232,281.236786 254.747406,273.812561 
	C255.132172,269.715057 256.643738,265.723389 257.647339,261.683990 
	C257.647339,261.683990 257.711639,261.721497 258.102783,261.758179 
	C259.299438,261.824982 260.104980,261.855103 260.956421,261.935425 
	C261.002289,261.985626 261.101379,262.078796 261.020050,262.468567 
	C258.873688,271.437775 260.188995,277.047882 266.054504,279.715698 
	C271.859802,282.356079 278.039703,284.455566 284.268799,285.812195 
	C292.708740,287.650269 301.151550,289.042358 308.194611,294.615356 
	C309.572296,295.705505 311.417755,296.204559 313.047455,296.976257 
	C313.047455,296.976257 312.997925,296.996643 313.020569,297.356140 
	C314.395874,300.838562 315.748566,303.961487 317.101288,307.084442 
	C317.254608,308.809479 317.407928,310.534546 317.154053,312.794495 
	C316.114471,316.067322 315.482147,318.805206 314.849792,321.543121 
	C314.849792,321.543121 314.909119,321.782593 314.481232,321.955078 
	C313.449280,323.249725 312.845215,324.371887 312.241150,325.494019 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M183.792526,414.113892 
	C190.704758,411.771759 196.600143,416.161896 203.031067,416.920929 
	C204.888428,417.140137 207.010864,416.209900 208.823532,415.390289 
	C210.497284,414.633484 211.918732,413.318665 214.183029,412.081726 
	C217.173920,412.399872 219.433075,412.885895 221.946548,413.560059 
	C222.952133,413.748169 223.703400,413.748169 224.454681,413.748169 
	C224.466553,413.582581 224.478424,413.416962 224.490295,413.251373 
	C223.703644,413.119049 222.917007,412.986725 221.927353,412.620087 
	C220.793045,411.992218 219.861771,411.598663 218.930481,411.205109 
	C218.124756,410.237671 217.224747,409.332153 216.538345,408.286407 
	C215.764999,407.108185 215.184021,405.803650 214.519379,404.554047 
	C216.051346,404.590668 217.734497,404.236694 219.079193,404.757141 
	C221.006149,405.502930 222.686005,406.887146 224.471359,407.998749 
	C224.785095,407.592194 225.098831,407.185608 225.412552,406.779053 
	C224.739792,405.652893 224.067032,404.526733 223.396301,403.403931 
	C223.580399,403.092438 223.723053,402.588928 223.911758,402.571045 
	C226.804443,402.296600 227.470932,401.011932 226.466888,398.231384 
	C225.849701,396.522186 226.071655,394.509979 226.287384,392.379272 
	C227.779831,392.761902 228.901733,393.399139 230.009674,394.021301 
	C229.995697,394.006256 229.966446,393.977417 230.016617,394.236542 
	C230.052414,394.671051 230.038055,394.846405 229.752213,395.026062 
	C229.015213,395.208191 228.549713,395.386017 228.084229,395.563843 
	C228.576904,395.882874 229.069565,396.201904 229.768921,396.672699 
	C230.223984,396.776215 230.472321,396.727997 230.720673,396.679779 
	C230.504639,396.431763 230.288574,396.183746 230.017059,395.695862 
	C229.961609,395.455994 229.968033,394.963593 230.354034,394.967834 
	C231.509216,394.986694 232.278397,395.001312 233.028900,395.002533 
	C233.010239,394.989166 232.974030,394.960876 232.939209,395.433502 
	C232.934708,396.536987 232.965042,397.167816 232.856110,397.623688 
	C232.575516,397.488800 232.434189,397.528900 232.292862,397.568970 
	C232.513214,397.744720 232.733566,397.920441 232.892258,398.198151 
	C232.830597,398.300171 232.951477,398.505676 233.095810,398.760193 
	C233.541885,399.511993 233.843628,400.009247 233.761047,400.333862 
	C232.569107,400.122742 231.761520,400.084290 230.981964,399.882324 
	C230.894119,399.740967 230.778229,399.763092 230.662338,399.785217 
	C230.784134,399.850525 230.905945,399.915863 231.173279,400.190552 
	C230.985992,402.961731 232.657837,403.939636 234.671494,404.658966 
	C235.445999,404.935638 236.208328,405.246399 237.137772,405.792633 
	C237.597153,406.769928 237.894958,407.496216 238.148758,408.422607 
	C238.104752,408.622772 238.026505,409.025085 237.724121,409.028564 
	C236.902710,409.129791 236.383667,409.227570 235.864639,409.325317 
	C235.873337,409.502411 235.882050,409.679474 235.890762,409.856567 
	C236.616470,409.917023 237.342163,409.977478 238.237579,410.320435 
	C239.268784,410.755676 240.130249,410.908417 241.001144,411.033325 
	C241.010559,411.005463 240.951752,411.007599 241.100067,411.268311 
	C241.802979,411.736023 242.357590,411.943054 242.931778,412.506256 
	C242.629318,414.243561 242.307297,415.624725 241.615707,416.996185 
	C240.155075,417.334686 239.064011,417.682831 237.565186,418.078430 
	C235.794922,418.020142 234.432434,417.914398 233.070679,417.592041 
	C233.071426,417.375427 233.067184,416.942169 233.388870,416.955627 
	C234.465469,416.884552 235.220398,416.800079 235.975327,416.715607 
	C235.885666,416.441071 235.796021,416.166504 235.706375,415.891968 
	C234.838852,416.248108 233.971344,416.604279 232.708038,416.940094 
	C231.520325,416.945343 230.728409,416.970917 229.521896,416.971619 
	C227.719894,416.960327 226.332489,416.973877 224.977661,416.983643 
	C225.010239,416.979889 225.033295,417.041290 224.977875,416.720947 
	C223.789703,416.524963 222.656921,416.649353 221.260468,416.909729 
	C220.674301,417.273590 220.351837,417.501434 219.732727,417.799194 
	C220.304337,418.245697 221.172577,418.622345 222.011566,419.009827 
	C221.982315,419.020660 221.957199,418.963562 222.095306,419.256897 
	C223.464661,419.336517 224.695908,419.122833 225.937103,418.698730 
	C225.947052,418.488342 225.966675,418.067535 226.324387,418.057129 
	C227.388382,418.120911 228.094681,418.195099 229.050598,418.446686 
	C229.891174,418.735718 230.482117,418.847351 231.457397,418.978577 
	C232.858597,419.125824 233.875488,419.253448 234.638641,419.515503 
	C234.662186,420.379608 234.939484,421.109314 235.623260,421.863831 
	C237.651474,422.026917 239.273209,422.165253 240.571930,422.423920 
	C237.858932,423.448914 235.468918,424.353607 232.632660,425.427216 
	C233.907227,426.272308 234.456406,426.636444 235.005585,427.000580 
	C227.875381,426.083557 220.710678,425.372894 213.625229,424.188202 
	C207.266373,423.124939 200.942261,421.740570 194.705078,420.107361 
	C191.226486,419.196503 187.956375,417.489441 184.252808,415.730469 
	C183.873032,414.917816 183.832779,414.515839 183.792526,414.113892 
M238.263275,412.046967 
	C238.007477,412.174835 237.751663,412.302673 237.495850,412.430511 
	C237.823044,412.596039 238.150223,412.761566 238.477417,412.927094 
	C238.574234,412.662445 238.671036,412.397797 238.263275,412.046967 
M214.508957,417.483917 
	C215.162262,417.649689 215.815552,417.815430 216.468857,417.981171 
	C216.511292,417.765137 216.553741,417.549103 216.596176,417.333069 
	C216.059433,417.261749 215.522675,417.190399 214.508957,417.483917 
M211.409210,416.486328 
	C211.789459,416.569977 212.169724,416.653595 212.549988,416.737213 
	C212.309494,416.534973 212.069000,416.332733 211.409210,416.486328 
z"/>
<path fill="#EAC461" opacity="1.000000" stroke="none" 
	d="
M192.008942,272.951447 
	C191.797821,272.890228 191.586700,272.829010 191.187103,272.162292 
	C190.589966,268.351074 188.979889,266.732422 185.591187,267.463348 
	C185.591187,267.463348 185.659409,267.125061 185.569229,267.033356 
	C185.202774,266.717194 184.926514,266.492737 184.650238,266.268280 
	C184.650238,266.268280 184.777908,266.198212 184.752563,265.878113 
	C183.886078,263.611237 183.278000,261.518311 182.164124,259.742676 
	C176.915634,251.375977 174.674744,242.313614 176.698898,232.653519 
	C177.889801,226.970108 180.254593,221.532700 182.112335,215.792847 
	C182.183655,215.346069 182.189789,215.090012 182.151672,214.832870 
	C182.151672,214.832870 182.288666,214.371887 182.648514,214.195084 
	C183.008362,214.018280 183.290665,213.844177 183.529678,213.826553 
	C184.162384,213.540817 184.556076,213.272720 185.135223,212.789505 
	C185.583954,211.687866 185.847214,210.801331 186.110458,209.914810 
	C186.199173,209.724670 186.287888,209.534515 186.956116,209.264954 
	C188.299759,210.134567 189.063873,211.083572 189.827988,212.032593 
	C189.806412,212.206100 189.784836,212.379623 189.296921,212.887329 
	C187.810226,214.861328 186.375092,216.376190 185.837234,218.161377 
	C182.482971,229.294144 183.470840,240.383545 187.106094,251.221130 
	C187.853012,253.447876 190.261414,255.117325 191.903992,257.043640 
	C191.903992,257.043640 191.968002,257.019318 191.968689,257.425659 
	C192.832916,259.554901 193.409592,261.516022 194.608704,262.960327 
	C200.350204,269.875824 207.632828,274.554382 216.349564,277.611450 
	C214.018143,278.292603 211.712158,278.544373 209.593948,278.027130 
	C203.903152,276.637390 198.292618,274.918976 192.650208,273.331085 
	C192.650208,273.331085 192.816849,273.193604 192.748444,273.137024 
	C192.680038,273.080444 192.389725,272.823486 192.389725,272.823486 
	C192.389725,272.823486 192.008942,272.951447 192.008942,272.951447 
z"/>
<path fill="#EF8D25" opacity="1.000000" stroke="none" 
	d="
M88.898758,305.943420 
	C90.323837,308.131927 91.748909,310.320404 93.174820,313.106995 
	C93.154770,315.790649 93.009048,317.883820 93.134850,319.960541 
	C93.649864,328.462769 100.904984,332.325745 105.703110,338.229736 
	C105.852509,338.715820 105.922920,338.906860 106.022736,339.350647 
	C106.108139,339.763458 106.164124,339.923584 106.295631,340.378693 
	C107.052742,341.445465 107.734329,342.217285 108.122772,343.045227 
	C107.296623,344.783264 106.763618,346.465210 105.836304,348.117462 
	C102.120941,344.935089 98.704498,341.875183 95.513992,338.595459 
	C93.544182,336.570557 91.918518,334.210846 90.138359,332.001434 
	C87.090935,325.081268 84.043503,318.161133 80.980728,310.459473 
	C79.528137,302.954895 78.036560,296.242767 76.678154,289.503754 
	C76.061729,286.445740 75.709312,283.334534 75.237129,280.247437 
	C75.645164,280.156525 76.053200,280.065613 76.461243,279.974701 
	C76.860023,280.805450 77.259483,281.635834 77.657471,282.466980 
	C81.404709,290.292389 85.151703,298.117920 88.898758,305.943420 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M238.192764,408.222473 
	C237.894958,407.496216 237.597153,406.769928 237.207260,405.474915 
	C239.783401,400.970337 235.521378,401.712463 234.145386,400.506500 
	C233.843628,400.009247 233.541885,399.511993 233.306198,398.503326 
	C233.923080,397.317841 234.473892,396.643738 235.260437,395.823181 
	C235.988647,395.137451 236.481140,394.598206 237.197052,393.934937 
	C237.609879,393.534576 237.799286,393.258240 238.208679,392.924622 
	C238.674500,392.731781 238.920334,392.596191 239.166168,392.460632 
	C238.774902,392.310272 238.383652,392.159912 237.905334,391.711792 
	C237.532745,391.113708 237.247208,390.813416 236.659943,390.545776 
	C235.583160,390.380127 234.808105,390.181885 233.674072,389.974731 
	C232.835861,389.971588 232.356644,389.977417 231.702240,389.908203 
	C231.300018,389.701843 231.128235,389.518616 231.027222,388.968933 
	C231.035080,388.444458 231.027451,388.234619 231.189758,387.925659 
	C231.586639,387.688568 231.778610,387.512451 232.020157,386.959351 
	C232.136810,386.389862 232.168884,386.159210 232.200958,385.928589 
	C234.486969,386.894958 237.036484,387.494537 239.002182,388.906738 
	C242.682999,391.551117 246.025558,394.666290 249.349335,397.883240 
	C247.478455,399.082153 245.767075,399.983429 243.692062,401.076172 
	C246.833191,402.787781 249.093018,404.019165 250.461975,404.765106 
	C253.038971,404.765106 254.963913,404.765106 256.782104,405.073364 
	C255.987122,406.267273 255.298874,407.152985 254.417221,408.287537 
	C257.388458,410.494293 260.287384,412.647308 263.027313,414.682251 
	C261.232819,415.295197 259.276520,415.963409 256.935425,416.763062 
	C257.880188,417.764221 258.428741,418.345520 258.656616,418.924042 
	C257.912537,418.976166 257.489136,419.031036 256.646301,419.026611 
	C253.229095,419.415985 250.231293,419.864685 246.128113,420.478851 
	C250.147446,421.066742 252.988083,421.482239 255.453308,421.923584 
	C254.075378,422.012909 253.072861,422.076385 251.617676,422.074799 
	C248.428253,422.001862 245.691498,421.993958 242.977173,421.993439 
	C242.999603,422.000793 243.044373,422.015900 242.761505,421.759033 
	C240.057999,421.614441 237.637390,421.726746 235.216766,421.839050 
	C234.939484,421.109314 234.662186,420.379608 235.045883,419.523621 
	C239.608719,419.397400 243.510590,419.397400 247.412445,419.397400 
	C247.413513,419.085846 247.414581,418.774323 247.415665,418.462769 
	C245.983856,418.347015 244.552048,418.231232 242.942581,417.919189 
	C242.505035,417.483948 242.245148,417.244904 241.985275,417.005859 
	C242.307297,415.624725 242.629318,414.243561 243.045471,412.291809 
	C242.410294,411.483337 241.681030,411.245453 240.951752,411.007599 
	C240.951752,411.007599 241.010559,411.005463 240.979324,410.646423 
	C240.945648,410.029114 240.943207,409.770813 240.920425,409.481628 
	C240.900070,409.450775 240.932770,409.384460 241.062561,409.272034 
	C241.192368,409.159637 241.474014,408.963226 241.775452,408.941345 
	C242.717484,408.947052 243.358047,408.974609 244.100204,409.128357 
	C244.409866,409.277496 244.617966,409.300385 244.826050,409.323303 
	C244.551849,409.214600 244.277664,409.105896 243.965454,408.928223 
	C243.927429,408.859253 243.923172,408.701813 243.772247,408.412109 
	C242.743378,408.080353 241.865448,408.038300 240.639282,408.042542 
	C239.591644,408.133362 238.892197,408.177917 238.192764,408.222473 
z"/>
<path fill="#EF8D25" opacity="1.000000" stroke="none" 
	d="
M300.855774,410.745087 
	C301.320831,410.586639 301.785889,410.428192 302.787170,410.385620 
	C303.923157,410.281311 304.522919,410.061157 305.122681,409.841003 
	C305.525940,409.648987 305.929169,409.456970 306.921478,409.366699 
	C309.129669,410.674530 312.119843,406.276520 312.928833,410.794006 
	C304.560089,413.883301 296.191376,416.972565 286.983704,419.989960 
	C280.992371,421.992126 274.592682,419.455139 270.173218,424.238556 
	C269.408356,424.438385 268.643494,424.638214 267.232971,424.625427 
	C264.708679,423.974670 262.830048,423.536530 260.942749,422.817688 
	C260.912903,422.351532 260.891785,422.166107 261.153076,421.778442 
	C265.858765,420.408539 268.180817,417.494080 268.882568,412.948486 
	C266.342194,411.989746 264.053040,411.125793 261.763885,410.261871 
	C261.847931,409.887207 261.931976,409.512573 262.016022,409.137909 
	C264.252380,408.794861 266.488708,408.451843 268.725067,408.108795 
	C268.982483,408.159821 269.239929,408.210846 269.999329,408.613220 
	C270.938599,408.999725 271.375946,409.034943 271.813263,409.070160 
	C273.327423,409.644318 274.841583,410.218445 277.107666,410.972961 
	C285.524994,411.017273 293.190369,410.881195 300.855774,410.745087 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M312.971741,296.647766 
	C311.417755,296.204559 309.572296,295.705505 308.194611,294.615356 
	C301.151550,289.042358 292.708740,287.650269 284.268799,285.812195 
	C278.039703,284.455566 271.859802,282.356079 266.054504,279.715698 
	C260.188995,277.047882 258.873688,271.437775 261.088348,262.402740 
	C261.237976,261.947113 261.197540,261.663452 261.197540,261.663452 
	C261.197540,261.663452 260.910522,261.885223 260.910522,261.885223 
	C260.104980,261.855103 259.299438,261.824982 258.098633,261.721191 
	C264.892761,254.531647 274.087616,252.000580 283.583954,252.587784 
	C291.827637,253.097549 299.935150,255.809860 308.039307,257.815857 
	C307.646790,258.063049 307.317902,258.041687 306.632629,257.916809 
	C303.636688,257.095734 300.997192,256.376709 298.357697,255.657684 
	C298.856384,255.483749 299.355072,255.309814 299.853760,255.135880 
	C304.321167,260.482635 308.788544,265.829407 313.290100,271.217041 
	C313.876190,269.796570 314.436005,268.439728 315.106873,267.240845 
	C315.388458,267.592224 315.571655,267.772858 315.767487,267.940643 
	C315.278351,269.468353 314.789215,270.996063 313.771667,272.810730 
	C312.821716,273.102570 312.400208,273.107422 311.978729,273.112274 
	C306.209259,266.284668 300.893250,258.813660 291.723724,256.170105 
	C282.889343,253.623169 274.268768,253.892990 266.684753,259.902802 
	C258.626465,266.288452 259.152130,275.016052 268.537201,279.181274 
	C275.717438,282.367981 283.646454,283.910492 291.296997,285.982788 
	C299.151550,288.110321 307.146240,289.838348 312.971741,296.647766 
z"/>
<path fill="#D7BC6C" opacity="1.000000" stroke="none" 
	d="
M192.790070,273.636078 
	C198.292618,274.918976 203.903152,276.637390 209.593948,278.027130 
	C211.712158,278.544373 214.018143,278.292603 216.349564,277.611450 
	C207.632828,274.554382 200.350204,269.875824 194.608704,262.960327 
	C193.409592,261.516022 192.832916,259.554901 191.963745,257.459534 
	C199.544281,266.746063 208.700165,274.046722 221.363922,275.320740 
	C225.567474,275.743591 229.900208,274.985992 234.164368,274.648071 
	C235.700089,274.526367 237.207916,274.052673 238.954163,273.693176 
	C238.954163,265.300415 238.954163,256.642365 239.032028,247.180588 
	C239.152176,245.919937 239.194458,245.463013 239.561951,245.198303 
	C240.252686,247.115433 240.900833,248.834961 240.933594,250.566162 
	C241.058136,257.146149 240.983765,263.729889 240.983765,270.574707 
	C243.938461,269.618713 246.411499,268.818542 248.884521,268.018402 
	C248.884521,269.787201 248.884521,271.556000 248.884521,273.387726 
	C244.208496,271.289032 240.761948,272.534698 236.814438,275.547455 
	C229.422928,281.188721 220.341431,282.089935 211.559372,280.526489 
	C205.161530,279.387543 199.126633,276.209869 192.790070,273.636078 
z"/>
<path fill="#EAC461" opacity="1.000000" stroke="none" 
	d="
M248.950775,267.783203 
	C246.411499,268.818542 243.938461,269.618713 240.983765,270.574707 
	C240.983765,263.729889 241.058136,257.146149 240.933594,250.566162 
	C240.900833,248.834961 240.252686,247.115433 239.516571,244.993164 
	C239.123337,244.291946 238.980255,244.063614 238.716736,243.910797 
	C238.582077,243.486038 238.447433,243.061279 238.374069,242.066299 
	C237.701065,239.794922 236.966782,238.093750 236.232498,236.392578 
	C240.521286,236.589981 244.810074,236.787384 249.128448,237.573486 
	C249.266159,238.480057 249.374283,238.797928 249.482391,239.115799 
	C249.488937,247.326950 249.495468,255.538116 249.300018,264.576233 
	C249.103195,265.958435 249.108368,266.513641 249.113541,267.068848 
	C249.113541,267.068848 249.017044,267.547974 248.950775,267.783203 
z"/>
<path fill="#DF9E46" opacity="1.000000" stroke="none" 
	d="
M88.927567,305.565857 
	C85.151703,298.117920 81.404709,290.292389 77.657471,282.466980 
	C77.259483,281.635834 76.860023,280.805450 76.461243,279.974701 
	C76.053200,280.065613 75.645164,280.156525 75.237129,280.247437 
	C75.709312,283.334534 76.061729,286.445740 76.678154,289.503754 
	C78.036560,296.242767 79.528137,302.954895 80.976746,310.041077 
	C74.024994,292.566467 71.309433,273.964142 71.466927,254.425568 
	C72.607086,250.681549 73.281677,247.440384 73.956268,244.199203 
	C74.289482,265.676697 79.828293,285.873871 88.927567,305.565857 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M250.319611,122.676537 
	C250.511368,118.053825 250.650711,113.640732 251.523300,109.377708 
	C251.755814,108.241760 254.146210,107.069649 255.702240,106.792725 
	C258.130615,106.360535 260.692474,106.678307 264.558563,106.678307 
	C266.748993,108.955231 270.151062,112.174370 268.768250,117.920052 
	C268.431732,119.318367 270.212341,121.226204 271.054382,122.972153 
	C264.937714,128.260483 256.704987,128.326675 250.319611,122.676537 
M264.481415,117.953285 
	C260.416473,122.993996 256.832916,121.228790 253.560883,116.408600 
	C252.647858,121.066521 254.539154,123.761040 258.374298,124.240692 
	C263.088318,124.830261 267.154541,121.234810 267.099060,116.526024 
	C267.040833,111.582497 264.059937,108.593117 259.479584,108.884796 
	C255.055710,109.166496 253.111450,111.191849 253.684311,114.921783 
	C261.190308,110.831490 261.915192,110.987160 264.481415,117.953285 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M106.230606,348.147125 
	C106.763618,346.465210 107.296623,344.783264 108.412239,343.139221 
	C110.314697,344.651520 111.432533,346.403351 112.996582,347.542542 
	C116.219330,349.889954 119.680321,351.910248 123.050323,354.388550 
	C123.032173,355.142303 123.006996,355.572632 123.009560,356.001648 
	C123.037308,356.000336 123.026176,355.945312 122.823631,356.032410 
	C122.314575,356.278473 122.198761,356.526459 122.026237,357.195404 
	C122.018257,361.208252 121.914146,364.955963 122.612549,368.547638 
	C123.115295,371.133118 124.687935,373.510559 126.276535,377.092987 
	C123.483856,375.937469 122.275421,375.437439 121.066994,374.937439 
	C117.977242,371.669006 114.887489,368.400574 112.093903,364.747620 
	C114.009018,363.518402 115.627945,362.673737 117.246880,361.829071 
	C115.789429,359.770325 114.614616,356.284973 112.819717,355.929352 
	C108.078964,354.990082 107.328926,351.637146 106.230606,348.147125 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M257.347748,261.824066 
	C256.643738,265.723389 255.132172,269.715057 254.747406,273.812561 
	C254.050232,281.236786 257.287140,286.499420 264.209503,288.850586 
	C271.532013,291.337646 279.035431,293.436920 286.603729,295.015930 
	C294.597534,296.683655 302.060272,299.436890 307.546936,305.432159 
	C311.347809,309.585327 313.559967,314.936188 309.686859,320.888916 
	C311.026825,311.552460 308.577942,305.139954 299.882141,301.476837 
	C292.013916,298.162354 283.396881,296.657318 275.181976,294.121429 
	C270.446472,292.659637 265.625092,291.181152 261.241516,288.932159 
	C250.280670,283.308716 248.513977,271.012207 257.347748,261.824066 
z"/>
<path fill="#D7BC6C" opacity="1.000000" stroke="none" 
	d="
M251.128983,320.003937 
	C251.128983,315.126160 251.128983,310.248383 251.128983,305.370605 
	C251.611313,305.121460 252.093643,304.872284 252.575974,304.623108 
	C256.666351,314.528076 262.093018,323.306458 271.821564,329.265625 
	C271.335144,334.422882 268.151184,330.053375 266.364777,331.212524 
	C266.364777,331.212524 266.169220,331.354980 265.901306,330.959656 
	C264.349213,330.111206 263.065063,329.658081 261.780884,329.204956 
	C261.780884,329.204956 261.860321,329.166779 261.688416,328.960083 
	C260.753693,328.480835 259.990845,328.208252 259.227997,327.935669 
	C259.000427,327.866272 258.772888,327.796906 258.240662,327.176758 
	C255.667007,324.418640 253.397995,322.211273 251.128983,320.003937 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M365.953278,157.992203 
	C351.264252,139.742386 333.450073,125.235008 313.030212,113.863327 
	C309.965607,112.156670 306.806549,110.619690 304.193604,108.244125 
	C329.303955,118.633156 349.417999,135.644211 366.784943,156.765747 
	C366.507904,157.525986 366.230591,157.759094 365.953278,157.992203 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M122.981819,356.002991 
	C123.006996,355.572632 123.032173,355.142303 123.232903,354.074158 
	C123.985489,351.603577 124.562523,349.770782 125.139557,347.937958 
	C128.228577,348.456848 131.317596,348.975769 134.555786,349.864624 
	C135.121964,350.553650 135.538971,350.872742 135.955994,351.191803 
	C135.929993,351.362305 135.903992,351.532776 135.495804,351.926422 
	C134.081085,352.437775 133.048569,352.725952 132.011688,353.242981 
	C132.007339,353.471802 132.000580,353.929657 132.180542,354.220581 
	C133.249847,354.665741 134.139191,354.820007 135.257828,355.235352 
	C136.624542,355.640137 137.761948,355.783813 138.899368,355.927521 
	C138.360840,356.514648 137.822311,357.101776 137.182693,358.094788 
	C137.081604,358.500702 137.094727,358.792938 136.779266,358.847778 
	C136.252655,358.934113 136.041504,358.965576 135.540054,359.033722 
	C134.798630,359.367188 134.347519,359.663910 133.718719,360.205750 
	C133.044510,362.136627 132.547989,363.822388 131.757141,365.666077 
	C131.018051,365.827881 130.573288,365.831818 129.960358,365.660156 
	C129.385452,365.513336 128.978729,365.542175 128.572006,365.570984 
	C128.939072,365.848450 129.306137,366.125946 129.726379,366.723633 
	C130.191620,368.035583 130.603729,369.027252 130.665741,369.970642 
	C126.064949,367.683197 123.060707,364.910095 124.834282,359.388489 
	C125.105118,358.545319 123.642632,357.145416 122.981819,356.002991 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M154.111237,113.047127 
	C145.305588,119.234619 135.789246,124.639381 127.883293,131.817749 
	C119.008385,139.875900 111.584732,149.533218 103.540398,158.504395 
	C102.423866,159.749542 101.368637,161.049667 100.285019,162.324341 
	C99.762398,161.884964 99.239769,161.445587 98.717148,161.006195 
	C105.328728,153.384293 111.339767,145.112381 118.705711,138.306976 
	C127.672165,130.022827 137.724136,122.901260 147.448166,115.461922 
	C149.023819,114.256470 151.333511,114.010498 153.686310,113.190933 
	C154.068726,113.060829 154.111237,113.047127 154.111237,113.047127 
z"/>
<path fill="#DF9E46" opacity="1.000000" stroke="none" 
	d="
M105.836304,348.117462 
	C107.328926,351.637146 108.078964,354.990082 112.819717,355.929352 
	C114.614616,356.284973 115.789429,359.770325 117.246880,361.829071 
	C115.627945,362.673737 114.009018,363.518402 111.979324,364.435059 
	C111.568558,364.507019 111.508690,364.506622 111.461319,364.204803 
	C110.989029,363.593872 110.564110,363.284760 110.139191,362.975647 
	C109.862892,362.726837 109.586594,362.477997 109.163521,361.729675 
	C108.581039,360.760925 108.145340,360.291656 107.709641,359.822418 
	C107.556396,359.723633 107.403152,359.624817 107.064941,359.364655 
	C106.879974,359.203278 106.863602,359.332489 106.863602,359.332489 
	C101.302246,350.459869 95.740883,341.587280 90.158936,332.358032 
	C91.918518,334.210846 93.544182,336.570557 95.513992,338.595459 
	C98.704498,341.875183 102.120941,344.935089 105.836304,348.117462 
z"/>
<path fill="#D8C379" opacity="1.000000" stroke="none" 
	d="
M394.663910,241.152435 
	C391.516266,242.962753 388.719849,244.847794 385.636566,245.727707 
	C384.332458,246.099884 381.818024,244.770660 380.938721,243.477173 
	C379.718536,241.682220 379.520081,239.192703 378.765228,236.590378 
	C383.970093,235.255707 388.694305,234.044296 394.080719,232.663071 
	C394.386536,235.711380 394.642853,238.266052 394.663910,241.152435 
z"/>
<path fill="#D7BC6C" opacity="1.000000" stroke="none" 
	d="
M293.399292,133.531464 
	C293.229523,134.529968 293.102173,135.168274 292.752045,136.923035 
	C291.211395,134.517960 290.163086,132.881424 289.065826,131.168503 
	C288.019348,131.467941 286.636566,131.863602 285.253784,132.259277 
	C285.395111,130.754181 285.311401,129.182373 285.733002,127.760376 
	C286.296631,125.859184 287.066833,123.961388 288.094482,122.272781 
	C289.017792,120.755608 291.103455,118.186913 291.480042,118.382721 
	C293.840363,119.610092 296.562683,121.114128 297.711578,123.293076 
	C298.297516,124.404289 295.779053,127.071846 294.817932,129.108658 
	C294.211731,130.393280 293.890656,131.812454 293.399292,133.531464 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M266.640076,331.461609 
	C268.151184,330.053375 271.335144,334.422882 272.111145,329.441833 
	C281.132446,333.606873 290.578094,334.310059 299.763214,329.960663 
	C303.250671,328.309235 305.798462,324.673462 308.933746,321.665955 
	C309.749542,322.888519 310.406128,324.387207 311.407379,325.926086 
	C305.570862,333.102112 297.019257,334.870941 288.413116,334.829254 
	C281.242340,334.794525 274.080933,332.823914 266.640076,331.461609 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M123.009567,356.001648 
	C123.642632,357.145416 125.105118,358.545319 124.834282,359.388489 
	C123.060707,364.910095 126.064949,367.683197 130.658371,369.950623 
	C131.001068,369.978912 130.961548,369.998688 131.062866,370.271118 
	C131.853622,370.982056 132.543060,371.420502 133.564911,372.070343 
	C133.297958,370.990204 133.162781,370.443207 133.041763,369.507385 
	C133.047119,368.597809 133.038284,368.077087 133.378662,367.655731 
	C135.486267,368.480438 137.244644,369.205811 139.272430,370.138489 
	C140.217575,370.878510 140.893295,371.411285 141.218414,372.188416 
	C138.820862,372.571533 136.773941,372.710236 134.485489,372.903564 
	C129.494003,375.760162 129.033386,376.983429 132.051086,379.306519 
	C132.433578,380.044678 132.716217,380.523224 132.999435,381.000854 
	C133.000000,381.000000 133.001740,380.998230 132.612518,381.004852 
	C131.964127,381.024719 131.704956,381.037964 131.433441,381.130829 
	C131.421097,381.210419 131.261108,381.191742 131.117218,381.050751 
	C130.661301,380.997284 130.349274,381.084808 130.196167,381.358856 
	C130.694260,381.885223 131.033432,382.225098 131.297455,382.653229 
	C131.222321,382.741486 131.072250,382.918213 131.072250,382.918213 
	C127.842934,380.453888 124.613609,377.989563 121.225639,375.231354 
	C122.275421,375.437439 123.483856,375.937469 126.276535,377.092987 
	C124.687935,373.510559 123.115295,371.133118 122.612549,368.547638 
	C121.914146,364.955963 122.018257,361.208252 122.203812,357.091492 
	C122.834732,356.459961 122.967186,356.223206 123.026176,355.945312 
	C123.026176,355.945312 123.037308,356.000336 123.009567,356.001648 
z"/>
<path fill="#D7BC6C" opacity="1.000000" stroke="none" 
	d="
M382.247192,214.251556 
	C374.560883,217.983337 372.437042,217.814240 369.984680,213.404297 
	C368.066620,209.955185 368.478027,206.968170 372.044617,204.920670 
	C374.344696,203.600250 376.767944,202.462097 379.229645,201.475983 
	C379.930420,201.195282 380.959229,201.733505 381.837585,201.896179 
	C381.443756,202.824051 381.307190,204.163849 380.606964,204.601196 
	C378.647430,205.825058 376.277435,206.432327 374.430237,207.778137 
	C373.526794,208.436356 373.424957,210.194763 372.960266,211.455185 
	C374.206482,211.909073 375.507660,212.836990 376.685455,212.700516 
	C378.267792,212.517197 379.704285,211.297852 381.297455,210.921478 
	C382.795135,210.567673 384.415039,210.731293 385.981171,210.667236 
	C384.847168,211.813644 383.713165,212.960052 382.247192,214.251556 
z"/>
<path fill="#D8C379" opacity="1.000000" stroke="none" 
	d="
M359.192657,184.792175 
	C358.852936,182.755783 357.877258,181.100586 358.061920,179.586945 
	C358.289520,177.721268 359.483276,175.973450 360.259521,174.174713 
	C362.386597,175.096130 365.066833,175.525269 366.464386,177.095856 
	C367.567719,178.335709 367.144653,180.977097 367.240875,183.003571 
	C367.250641,183.208481 365.884094,183.821716 365.642242,183.630554 
	C364.948669,183.082474 364.388916,182.275284 363.990540,181.464752 
	C363.408630,180.280930 363.013123,179.005493 361.983124,177.928284 
	C360.631409,181.739700 366.424042,186.550293 360.125031,188.915787 
	C358.552368,189.506363 355.402985,187.771729 353.898224,186.216003 
	C351.814606,184.061798 350.870148,181.070160 354.540955,177.707397 
	C355.012268,179.477936 355.578217,180.667236 355.595093,181.864288 
	C355.629242,184.287674 355.968872,186.102936 359.192657,184.792175 
z"/>
<path fill="#D8C379" opacity="1.000000" stroke="none" 
	d="
M202.088608,113.375412 
	C202.300308,113.460884 202.512009,113.546349 203.056931,114.193985 
	C203.238647,116.171967 203.087158,117.587799 202.935669,119.003624 
	C199.044998,124.943314 191.733688,124.410599 188.648773,119.104874 
	C186.660339,115.685020 187.995117,110.905609 191.449234,108.695572 
	C194.967651,106.444397 199.037460,107.472260 201.523666,111.623482 
	C199.683701,111.557350 198.246475,110.852211 196.881104,110.971252 
	C195.224777,111.115669 193.011047,111.498016 192.178314,112.621933 
	C191.318680,113.782127 191.129456,116.457253 191.918640,117.557259 
	C192.856674,118.864761 195.096848,119.526169 196.879105,119.817825 
	C197.764923,119.962776 198.888336,118.655838 200.272278,117.770210 
	C198.390625,117.015053 197.207123,116.540077 194.790436,115.570190 
	C198.119034,114.569183 200.103821,113.972298 202.088608,113.375412 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M124.811874,347.827881 
	C124.562523,349.770782 123.985489,351.603577 123.225868,353.750793 
	C119.680321,351.910248 116.219330,349.889954 112.996582,347.542542 
	C111.432533,346.403351 110.314697,344.651520 108.705383,343.083069 
	C107.734329,342.217285 107.052742,341.445465 106.401306,340.250122 
	C106.285423,339.583740 106.139374,339.340820 105.993324,339.097931 
	C105.922920,338.906860 105.852509,338.715820 106.026657,338.278564 
	C108.522202,339.173492 109.650276,338.246368 110.051292,335.980072 
	C114.862259,339.892639 119.673218,343.805237 124.811874,347.827881 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M109.754204,335.793823 
	C109.650276,338.246368 108.522202,339.173492 105.947678,337.983521 
	C100.904984,332.325745 93.649864,328.462769 93.134850,319.960541 
	C93.009048,317.883820 93.154770,315.790649 93.306259,313.408142 
	C97.484398,318.755463 101.531921,324.399628 105.779907,330.433716 
	C105.980377,330.823608 106.256958,330.971588 106.256958,330.971588 
	C107.323669,332.516907 108.390396,334.062225 109.754204,335.793823 
z"/>
<path fill="#EAC461" opacity="1.000000" stroke="none" 
	d="
M223.687637,201.123993 
	C223.306931,201.102219 222.926208,201.080460 222.279236,200.658478 
	C222.296753,199.502548 222.580521,198.746841 223.239319,198.052414 
	C224.366074,198.086334 225.117813,198.058975 225.869537,198.031616 
	C229.746201,199.250381 233.622864,200.469131 237.719299,202.080475 
	C239.738297,203.620270 241.638580,204.637527 243.315674,205.941818 
	C246.785782,208.640549 246.703735,210.913162 243.374863,214.189682 
	C240.587311,211.638092 238.056244,208.679672 234.929245,206.630630 
	C231.466217,204.361420 227.458282,202.923767 223.687637,201.123993 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M366.078827,158.307648 
	C366.230591,157.759094 366.507904,157.525986 366.932434,157.061920 
	C379.080353,171.103470 387.659088,187.285141 393.481079,205.026489 
	C385.814240,188.700943 377.596680,172.711960 366.078827,158.307648 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M378.942749,351.982452 
	C374.880432,357.953461 371.454651,364.511780 366.610809,369.761749 
	C358.794434,378.233551 350.044159,385.843719 341.244141,393.734497 
	C340.606110,393.404755 340.375031,393.200378 340.106750,393.038025 
	C353.931610,382.237885 365.632233,369.451660 375.683411,355.106537 
	C376.401184,354.082153 377.555786,353.363861 378.740601,352.248657 
	C378.973938,351.994110 378.942780,351.982452 378.942749,351.982452 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M268.490540,407.899170 
	C266.488708,408.451843 264.252380,408.794861 262.016022,409.137909 
	C261.931976,409.512573 261.847931,409.887207 261.763885,410.261871 
	C264.053040,411.125793 266.342194,411.989746 268.882568,412.948486 
	C268.180817,417.494080 265.858765,420.408539 260.869812,421.763367 
	C259.861816,420.942596 259.419556,419.934692 258.977295,418.926788 
	C258.428741,418.345520 257.880188,417.764221 256.935425,416.763062 
	C259.276520,415.963409 261.232819,415.295197 263.027313,414.682251 
	C260.287384,412.647308 257.388458,410.494293 254.417221,408.287537 
	C255.298874,407.152985 255.987122,406.267273 257.184631,405.129578 
	C260.416992,405.570251 263.140076,406.262939 265.863190,406.955627 
	C266.660797,407.200256 267.458405,407.444916 268.490540,407.899170 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M190.057434,211.904388 
	C189.063873,211.083572 188.299759,210.134567 187.257111,209.172974 
	C194.714233,200.534729 204.588608,196.766510 216.115997,196.615906 
	C215.408447,197.178101 214.545456,197.523972 213.131790,198.090561 
	C214.854507,198.766174 215.860352,199.160645 216.866196,199.555115 
	C207.851120,198.704239 199.975510,201.228424 192.902954,207.657425 
	C192.158386,207.821396 191.872635,207.818344 191.586884,207.815277 
	C191.697891,208.203629 191.808899,208.591980 191.919891,208.980331 
	C191.375565,209.912292 190.831223,210.844238 190.057434,211.904388 
z"/>
<path fill="#D7BC6C" opacity="1.000000" stroke="none" 
	d="
M182.091400,215.984634 
	C180.254593,221.532700 177.889801,226.970108 176.698898,232.653519 
	C174.674744,242.313614 176.915634,251.375977 182.164124,259.742676 
	C183.278000,261.518311 183.886078,263.611237 184.710846,265.937805 
	C172.771332,253.558670 171.135818,232.629654 181.241638,216.294861 
	C181.677353,216.032425 182.091400,215.984634 182.091400,215.984634 
z"/>
<path fill="#D8C379" opacity="1.000000" stroke="none" 
	d="
M319.257629,132.701782 
	C320.292633,134.877899 320.998810,136.931641 321.744263,139.099579 
	C323.757385,138.903625 326.295929,138.656525 329.288666,138.365219 
	C324.637787,142.267761 320.903595,145.443954 317.096741,148.530563 
	C316.708038,148.845734 315.743591,148.854477 315.300568,148.585938 
	C314.853851,148.315140 314.371307,147.136856 314.506622,147.012756 
	C318.825592,143.051575 316.933472,137.215042 319.257629,132.701782 
z"/>
<path fill="#D7BC6C" opacity="1.000000" stroke="none" 
	d="
M223.929688,201.372894 
	C227.458282,202.923767 231.466217,204.361420 234.929245,206.630630 
	C238.056244,208.679672 240.587311,211.638092 243.374863,214.189682 
	C246.703735,210.913162 246.785782,208.640549 243.315674,205.941818 
	C241.638580,204.637527 239.738297,203.620270 237.965912,202.237488 
	C248.167740,203.008194 250.061447,208.137192 243.926300,219.159271 
	C238.640930,211.693375 232.366348,205.535263 223.929688,201.372894 
z"/>
<path fill="#D7BC6C" opacity="1.000000" stroke="none" 
	d="
M191.823257,256.684967 
	C190.261414,255.117325 187.853012,253.447876 187.106094,251.221130 
	C183.470840,240.383545 182.482971,229.294144 185.837234,218.161377 
	C186.375092,216.376190 187.810226,214.861328 189.108185,213.069443 
	C183.082916,227.771851 184.261917,242.220139 191.823257,256.684967 
z"/>
<path fill="#D8C379" opacity="1.000000" stroke="none" 
	d="
M223.896729,106.815933 
	C224.776245,105.831665 225.638077,105.270866 227.174103,104.271355 
	C227.174103,109.193146 227.174103,112.961426 227.174103,116.285378 
	C229.256210,117.476059 230.922150,118.428757 232.588104,119.381454 
	C229.898056,120.017601 227.208008,120.653748 223.879028,121.440987 
	C223.879028,116.080719 223.879028,111.660057 223.896729,106.815933 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M313.283569,410.715393 
	C312.119843,406.276520 309.129669,410.674530 307.259979,409.337830 
	C313.692078,406.469635 320.374725,403.731995 327.653625,400.876068 
	C328.567505,400.638672 328.812500,400.433044 328.984833,400.140839 
	C329.608673,399.747559 330.232483,399.354279 331.283997,398.965515 
	C332.144043,399.239929 332.576385,399.509827 333.008728,399.779724 
	C326.551941,403.398712 320.095154,407.017731 313.283569,410.715393 
z"/>
<path fill="#D8C379" opacity="1.000000" stroke="none" 
	d="
M71.625366,240.956238 
	C72.896111,232.184692 74.166847,223.413147 75.437592,214.641602 
	C75.881790,214.715607 76.325996,214.789627 76.770195,214.863632 
	C75.878777,223.307724 74.987350,231.751816 73.719482,240.603027 
	C72.770493,240.992157 72.197929,240.974197 71.625366,240.956238 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M265.629028,406.739471 
	C263.140076,406.262939 260.416992,405.570251 257.291382,404.821320 
	C254.963913,404.765106 253.038971,404.765106 250.461975,404.765106 
	C249.093018,404.019165 246.833191,402.787781 243.692062,401.076172 
	C245.767075,399.983429 247.478455,399.082153 249.541351,398.159668 
	C250.158676,398.291565 250.398392,398.478180 250.866455,399.104126 
	C251.413391,399.660828 251.705948,399.811676 251.998505,399.962524 
	C256.463959,402.149445 260.929443,404.336395 265.629028,406.739471 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M202.087463,113.117371 
	C200.103821,113.972298 198.119034,114.569183 194.790436,115.570190 
	C197.207123,116.540077 198.390625,117.015053 200.272278,117.770210 
	C198.888336,118.655838 197.764923,119.962776 196.879105,119.817825 
	C195.096848,119.526169 192.856674,118.864761 191.918640,117.557259 
	C191.129456,116.457253 191.318680,113.782127 192.178314,112.621933 
	C193.011047,111.498016 195.224777,111.115669 196.881104,110.971252 
	C198.246475,110.852211 199.683701,111.557350 201.497803,111.870789 
	C201.966461,112.182655 202.026382,112.520996 202.087463,113.117371 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M235.353973,427.016724 
	C234.456406,426.636444 233.907227,426.272308 232.632660,425.427216 
	C235.468918,424.353607 237.858932,423.448914 240.924683,422.339722 
	C242.081757,422.095428 242.563065,422.055664 243.044373,422.015930 
	C243.044373,422.015900 242.999603,422.000793 243.296967,422.245270 
	C246.420334,422.608521 249.246353,422.727295 252.502441,422.883057 
	C255.222839,423.004272 257.513184,423.088531 259.488892,423.422546 
	C254.398407,423.895905 249.622559,424.119415 244.846710,424.342957 
	C244.843002,424.461304 244.839279,424.579651 244.835571,424.697968 
	C246.601746,424.854248 248.367905,425.010498 250.134079,425.166748 
	C246.356781,425.816162 242.579483,426.465546 238.263947,427.018005 
	C237.469208,426.795105 237.259293,426.854767 237.095993,427.100037 
	C236.631454,427.077637 236.166901,427.055237 235.353973,427.016724 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M395.004059,319.001770 
	C391.972839,326.962646 388.941650,334.923492 385.910431,342.884369 
	C385.203796,342.588074 384.497131,342.291779 383.790497,341.995483 
	C387.384308,334.495178 390.978119,326.994873 394.782867,319.250916 
	C394.993774,319.007233 395.004059,319.001770 395.004059,319.001770 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M218.054214,370.053619 
	C221.963699,373.912628 225.920349,377.726318 229.726349,381.684845 
	C230.234131,382.213013 229.978622,383.475037 230.038101,384.698059 
	C229.999619,384.998932 230.022049,384.974976 229.689362,384.942993 
	C228.932465,384.774292 228.508255,384.637573 227.966705,384.449341 
	C227.849380,384.397827 227.634888,384.542847 227.511642,384.250793 
	C226.272980,382.967957 225.157562,381.977142 223.949127,380.743652 
	C223.588242,380.294891 223.320374,380.088806 222.902100,379.643402 
	C222.167221,378.695648 221.582764,377.987244 220.938416,376.917633 
	C219.937088,374.388855 218.995651,372.221222 218.054214,370.053619 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M97.287285,167.404083 
	C93.534386,174.006683 89.954247,180.288361 86.374115,186.570053 
	C86.031555,186.387741 85.688995,186.205444 85.346436,186.023132 
	C89.110939,178.934387 92.875450,171.845657 96.639961,164.756912 
	C97.074715,164.960983 97.509468,165.165039 97.944221,165.369110 
	C97.782829,165.940460 97.621437,166.511826 97.287285,167.404083 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M155.038315,395.032532 
	C153.567368,395.225952 152.096405,395.419373 150.301239,395.811523 
	C149.977005,396.010254 149.601456,395.887573 149.207947,395.626129 
	C148.240845,395.210327 147.667236,395.055969 147.093628,394.901611 
	C147.093628,394.901611 147.015717,395.000458 146.962662,394.691528 
	C146.274597,393.777710 145.639587,393.172821 144.874527,392.260345 
	C144.495117,391.339386 144.245758,390.726044 143.790955,389.688782 
	C143.357742,389.048523 143.129990,388.832245 143.360626,388.593292 
	C145.248520,388.914185 146.678040,389.257812 148.107559,389.601440 
	C149.275131,389.929260 150.442688,390.257141 151.716812,391.158630 
	C152.895035,392.832336 153.966675,393.932404 155.038315,395.032532 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M402.886841,268.663452 
	C403.168365,263.537720 403.444672,258.858887 403.720947,254.180069 
	C403.978455,254.194855 404.235962,254.209641 404.493469,254.224426 
	C404.493469,260.842743 404.493469,267.461090 404.493469,274.079437 
	C404.034271,274.091034 403.575073,274.102661 403.115875,274.114258 
	C403.037781,272.446320 402.959717,270.778351 402.886841,268.663452 
z"/>
<path fill="#EAC461" opacity="1.000000" stroke="none" 
	d="
M71.345169,241.346680 
	C72.197929,240.974197 72.770493,240.992157 73.630371,240.996567 
	C73.967682,241.786621 74.017685,242.590256 74.011978,243.796539 
	C73.281677,247.440384 72.607086,250.681549 71.557831,253.966461 
	C71.143761,249.919159 71.104362,245.828125 71.345169,241.346680 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M138.925659,389.146057 
	C135.189285,388.916321 132.401230,387.335236 131.081726,383.283142 
	C131.072250,382.918213 131.222321,382.741486 131.573334,382.709778 
	C132.284149,382.783173 132.643921,382.888275 133.058319,383.021149 
	C133.112930,383.048920 132.991455,383.004028 132.911072,382.893585 
	C132.830704,382.783142 132.945282,382.535126 132.964828,382.151093 
	C132.990158,381.510773 132.995956,381.254486 133.001740,380.998230 
	C133.001740,380.998230 133.000000,381.000000 133.341370,380.983093 
	C133.910843,380.958130 134.138962,380.950073 134.450836,380.925354 
	C134.534622,380.908691 135.018158,380.972626 135.400192,381.333771 
	C136.274597,382.194611 136.766968,382.694336 137.399902,383.468109 
	C138.002213,385.543488 138.463943,387.344757 138.925659,389.146057 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M270.546539,424.304749 
	C274.592682,419.455139 280.992371,421.992126 286.560486,419.967010 
	C281.624084,421.467621 276.271973,422.919281 270.546539,424.304749 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M250.505234,425.126312 
	C248.367905,425.010498 246.601746,424.854248 244.835571,424.697968 
	C244.839279,424.579651 244.843002,424.461304 244.846710,424.342957 
	C249.622559,424.119415 254.398407,423.895905 259.774963,423.390045 
	C260.567627,423.104614 260.759552,423.101501 260.951447,423.098389 
	C262.830048,423.536530 264.708679,423.974670 266.834229,424.615417 
	C261.679596,424.907288 256.277985,424.996582 250.505234,425.126312 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M300.429932,410.689087 
	C293.190369,410.881195 285.524994,411.017273 277.446350,411.065735 
	C284.690125,410.863098 292.347076,410.748077 300.429932,410.689087 
z"/>
<path fill="#D7BC6C" opacity="1.000000" stroke="none" 
	d="
M315.870575,267.868195 
	C315.571655,267.772858 315.388458,267.592224 315.107422,266.854309 
	C313.937347,264.831909 313.034363,263.200623 311.776886,261.916534 
	C310.346161,260.455536 308.598816,259.304565 306.989105,258.018860 
	C307.317902,258.041687 307.646790,258.063049 308.221741,258.076111 
	C311.096436,259.664490 313.781616,261.179352 316.292847,262.942017 
	C316.667145,263.204773 316.223541,264.632751 316.035187,266.048920 
	C315.867310,266.902191 315.861664,267.228882 315.895142,267.556274 
	C315.895142,267.556274 315.973663,267.795746 315.870575,267.868195 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M183.480698,414.070068 
	C183.832779,414.515839 183.873032,414.917816 183.906494,415.623535 
	C178.957520,413.808929 174.015320,411.690521 169.014999,409.213257 
	C172.729279,410.211456 176.501663,411.568481 180.756271,413.042786 
	C181.238480,413.160065 181.501633,413.195709 181.501633,413.195709 
	C182.057373,413.472565 182.613113,413.749420 183.480698,414.070068 
z"/>
<path fill="#D7BC6C" opacity="1.000000" stroke="none" 
	d="
M217.197845,199.765259 
	C215.860352,199.160645 214.854507,198.766174 213.131790,198.090561 
	C214.545456,197.523972 215.408447,197.178101 216.534027,196.713455 
	C218.708817,196.662094 220.621017,196.729492 222.736740,197.259796 
	C222.940277,197.722717 222.864273,197.991119 222.864288,197.991119 
	C222.580521,198.746841 222.296753,199.502548 222.005280,200.569595 
	C220.508224,200.579071 219.018875,200.277237 217.197845,199.765259 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M333.306763,399.650909 
	C332.576385,399.509827 332.144043,399.239929 331.545868,398.877197 
	C334.068481,396.945038 336.756927,395.105713 339.776062,393.152222 
	C340.375031,393.200378 340.606110,393.404755 340.926758,393.846008 
	C338.570618,395.867950 336.087708,397.695038 333.306763,399.650909 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M251.051926,320.275330 
	C253.397995,322.211273 255.667007,324.418640 257.981750,326.931213 
	C255.676651,325.006561 253.325760,322.776611 251.051926,320.275330 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M217.918076,369.816467 
	C218.995651,372.221222 219.937088,374.388855 220.658447,376.747864 
	C219.672470,376.560455 218.906586,376.181610 218.139343,375.797058 
	C218.137985,375.791321 218.170547,375.779297 218.170547,375.779297 
	C216.430695,374.200958 214.690826,372.622650 212.875916,370.643982 
	C212.800842,370.243683 213.076630,369.907349 213.076630,369.907349 
	C213.291153,369.927795 213.505692,369.948242 214.303070,369.935608 
	C215.577179,369.557556 216.268463,369.212585 216.959747,368.867615 
	C217.233810,369.104858 217.507874,369.342102 217.918076,369.816467 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M139.085541,389.379761 
	C138.463943,387.344757 138.002213,385.543488 137.602249,383.466431 
	C137.947235,383.222443 138.206741,383.158295 138.662537,383.236572 
	C139.590729,383.964264 140.298965,384.453613 141.058365,385.216431 
	C141.063995,385.669739 141.018463,385.849548 140.984894,386.013428 
	C140.996872,385.997528 141.019867,385.965088 140.791840,386.038086 
	C140.714920,386.418121 140.866013,386.725128 141.008057,387.017151 
	C140.998993,387.002167 140.979996,386.972778 140.909561,387.266968 
	C140.719330,387.723358 140.599518,387.885559 140.565918,388.055054 
	C140.851822,388.241455 140.910492,388.390594 140.869019,389.026642 
	C141.650558,390.261841 142.391190,390.980194 143.131805,391.698578 
	C140.138931,394.167358 140.192001,390.958801 139.085541,389.379761 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M317.210999,306.783020 
	C315.748566,303.961487 314.395874,300.838562 313.035767,297.334045 
	C314.459137,300.128845 315.889923,303.305237 317.210999,306.783020 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M235.886108,236.240204 
	C236.966782,238.093750 237.701065,239.794922 238.317322,241.736938 
	C236.248001,240.322464 234.296722,238.667130 231.708694,236.471619 
	C233.093948,236.332855 234.316833,236.210342 235.886108,236.240204 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M203.281311,118.937286 
	C203.087158,117.587799 203.238647,116.171967 203.371552,114.329224 
	C204.806183,114.562485 206.259369,115.222679 209.057709,116.493965 
	C206.224792,117.733917 204.925888,118.302429 203.281311,118.937286 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M315.116241,321.350464 
	C315.482147,318.805206 316.114471,316.067322 317.009766,313.142334 
	C316.642670,315.689453 316.012665,318.423645 315.116241,321.350464 
z"/>
<path fill="#D7BC6C" opacity="1.000000" stroke="none" 
	d="
M185.755798,267.729553 
	C188.979889,266.732422 190.589966,268.351074 190.997711,271.892365 
	C189.304642,270.817200 187.612518,269.406464 185.755798,267.729553 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M378.980164,352.008789 
	C380.142487,350.110443 381.298584,348.197418 382.454681,346.284424 
	C382.481689,346.293945 382.508728,346.303467 382.535767,346.313019 
	C381.471130,348.035736 380.406464,349.758453 379.142303,351.731812 
	C378.942780,351.982452 378.973938,351.994110 378.980164,352.008789 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M216.814819,368.592834 
	C216.268463,369.212585 215.577179,369.557556 214.621902,369.988892 
	C213.876648,368.945862 213.395386,367.816437 212.951691,366.319336 
	C214.216171,366.740448 215.443039,367.529236 216.814819,368.592834 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M185.855804,210.009003 
	C185.847214,210.801331 185.583954,211.687866 184.864517,212.819183 
	C184.060745,213.165405 183.713150,213.266800 183.327408,213.326920 
	C183.289246,213.285645 183.214874,213.201309 183.214874,213.201294 
	C184.010284,212.168594 184.805725,211.135895 185.855804,210.009003 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M312.535553,325.356659 
	C312.845215,324.371887 313.449280,323.249725 314.372803,322.013855 
	C314.071472,323.006561 313.450684,324.112915 312.535553,325.356659 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M145.004578,392.567963 
	C145.639587,393.172821 146.274597,393.777710 146.997253,394.640594 
	C145.172699,395.513977 143.708878,395.457214 144.260345,392.533020 
	C144.703781,392.354126 144.854172,392.461060 145.004578,392.567963 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M261.988586,329.474518 
	C263.065063,329.658081 264.349213,330.111206 265.822327,330.867981 
	C264.739624,330.695801 263.467957,330.219940 261.988586,329.474518 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M223.239319,198.052399 
	C222.864273,197.991119 222.940277,197.722717 222.990082,197.592545 
	C223.852646,197.591888 224.665375,197.721405 225.673828,197.941269 
	C225.117813,198.058975 224.366074,198.086334 223.239319,198.052399 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M136.242188,351.139709 
	C135.538971,350.872742 135.121964,350.553650 134.824203,350.045166 
	C135.757523,350.016968 136.571564,350.178162 137.528412,350.618896 
	C137.290253,350.961487 136.909302,351.024567 136.242188,351.139709 
z"/>
<path fill="#EAC461" opacity="1.000000" stroke="none" 
	d="
M312.028839,273.339539 
	C312.400208,273.107422 312.821716,273.102570 313.558838,273.082214 
	C313.275940,273.233398 312.677460,273.400116 312.028839,273.339539 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M249.302216,266.963348 
	C249.108368,266.513641 249.103195,265.958435 249.270264,265.020935 
	C249.458633,265.378387 249.474762,266.118134 249.302216,266.963348 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M144.874527,392.260345 
	C144.854172,392.461060 144.703781,392.354126 144.279449,392.210693 
	C143.874390,392.069427 143.743256,391.964630 143.371964,391.779236 
	C142.391190,390.980194 141.650558,390.261841 141.387878,389.324646 
	C142.576004,389.441437 143.286209,389.777069 143.996399,390.112701 
	C144.245758,390.726044 144.495117,391.339386 144.874527,392.260345 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M304.846680,409.813965 
	C304.522919,410.061157 303.923157,410.281311 303.156616,410.366516 
	C303.516785,410.083344 304.043732,409.935150 304.846680,409.813965 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M147.064011,395.175171 
	C147.667236,395.055969 148.240845,395.210327 149.014313,395.587097 
	C148.487579,395.689270 147.760986,395.569031 147.064011,395.175171 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M238.640869,244.220337 
	C238.980255,244.063614 239.123337,244.291946 239.191345,244.800934 
	C239.194458,245.463013 239.152176,245.919937 239.021057,246.709167 
	C238.809830,246.204269 238.687424,245.367081 238.640869,244.220337 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M162.078018,406.313049 
	C162.499481,406.271973 163.148254,406.433990 163.915100,406.812592 
	C163.457214,406.858185 162.881271,406.687164 162.078018,406.313049 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M259.416321,328.184021 
	C259.990845,328.208252 260.753693,328.480835 261.702637,329.001831 
	C261.127380,328.977600 260.365997,328.704987 259.416321,328.184021 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M107.722992,360.070618 
	C108.145340,360.291656 108.581039,360.760925 109.046265,361.474792 
	C108.629303,361.252533 108.182823,360.785675 107.722992,360.070618 
z"/>
<path fill="#EAC461" opacity="1.000000" stroke="none" 
	d="
M192.185165,208.927185 
	C191.808899,208.591980 191.697891,208.203629 191.586884,207.815277 
	C191.872635,207.818344 192.158386,207.821396 192.637909,207.878510 
	C192.704605,208.246368 192.577515,208.560196 192.185165,208.927185 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M210.942291,364.725952 
	C210.509583,364.885040 209.970352,364.757721 209.265900,364.402374 
	C209.679016,364.262756 210.257401,364.351166 210.942291,364.725952 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M110.196732,363.237701 
	C110.564110,363.284760 110.989029,363.593872 111.455513,364.175385 
	C111.082817,364.131775 110.668549,363.815765 110.196732,363.237701 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M182.112335,215.792847 
	C182.091400,215.984634 181.677353,216.032425 181.469177,216.034683 
	C181.423447,215.674759 181.585907,215.312592 181.950012,214.891632 
	C182.189789,215.090012 182.183655,215.346069 182.112335,215.792847 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M154.060165,113.077148 
	C154.237381,112.932777 154.423157,112.772079 154.608932,112.611374 
	C154.507889,112.506142 154.406845,112.400902 154.305817,112.295670 
	C154.126556,112.475616 153.947296,112.655556 153.939636,112.941315 
	C154.111237,113.047127 154.068726,113.060829 154.060165,113.077148 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M316.025818,267.454834 
	C315.861664,267.228882 315.867310,266.902191 315.956970,266.335541 
	C316.053436,266.514404 316.104950,266.933899 316.025818,267.454834 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M271.635590,408.933594 
	C271.375946,409.034943 270.938599,408.999725 270.337585,408.814545 
	C270.601929,408.708740 271.029907,408.752899 271.635590,408.933594 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M212.898224,365.723206 
	C212.442200,365.858978 211.833694,365.713196 211.096985,365.264038 
	C211.561111,365.121033 212.153442,365.281372 212.898224,365.723206 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M183.290665,213.844177 
	C183.290665,213.844177 183.008362,214.018280 182.848480,214.061539 
	C182.580933,213.823318 182.638000,213.580551 183.037338,213.288879 
	C183.214874,213.201309 183.289246,213.285645 183.289352,213.442276 
	C183.289459,213.598907 183.290665,213.844177 183.290665,213.844177 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M237.291977,427.114319 
	C237.259293,426.854767 237.469208,426.795105 237.803284,427.019958 
	C237.880844,427.118866 237.487961,427.128601 237.291977,427.114319 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M229.987152,385.010620 
	C230.340851,385.068604 230.707001,385.114929 231.130829,385.477234 
	C231.069077,385.918488 230.949615,386.043732 230.622803,386.025757 
	C230.284348,385.579987 230.153198,385.277496 230.022049,384.974976 
	C230.022049,384.974976 229.999619,384.998932 229.987152,385.010620 
z"/>
<path fill="#D7BC6C" opacity="1.000000" stroke="none" 
	d="
M192.137283,273.124023 
	C192.008942,272.951447 192.389725,272.823486 192.389725,272.823486 
	C192.389725,272.823486 192.680038,273.080444 192.684891,273.224365 
	C192.689743,273.368286 192.265625,273.296570 192.137283,273.124023 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M394.997345,318.998779 
	C395.000916,318.990356 395.200928,318.910492 395.200928,318.910492 
	C395.200928,318.910492 394.959381,318.860321 394.981720,318.931030 
	C395.004059,319.001770 394.993774,319.007233 394.997345,318.998779 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M106.301804,330.868988 
	C106.256958,330.971588 105.980377,330.823608 105.982635,330.740814 
	C105.984901,330.658020 106.346649,330.766388 106.301804,330.868988 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M106.931625,359.289246 
	C106.863602,359.332489 106.879974,359.203278 106.850418,359.195007 
	C106.820869,359.186737 106.999649,359.246033 106.931625,359.289246 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M328.767944,400.231201 
	C328.812500,400.433044 328.567505,400.638672 328.011292,400.800720 
	C328.018127,400.648712 328.277588,400.474670 328.767944,400.231201 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M251.840424,399.782135 
	C251.705948,399.811676 251.413391,399.660828 251.010132,399.282227 
	C251.204041,399.174469 251.465027,399.356873 251.840424,399.782135 
z"/>
<path fill="#D7BC6C" opacity="1.000000" stroke="none" 
	d="
M184.722656,266.533112 
	C184.926514,266.492737 185.202774,266.717194 185.553741,267.205170 
	C185.350647,267.245117 185.072861,267.021515 184.722656,266.533112 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M249.629318,238.896820 
	C249.374283,238.797928 249.266159,238.480057 249.306015,237.942429 
	C249.561417,238.041061 249.668839,238.359467 249.629318,238.896820 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M232.051483,385.789978 
	C232.168884,386.159210 232.136810,386.389862 231.810989,386.794189 
	C231.288208,386.701569 231.059174,386.435272 230.830139,386.168976 
	C230.949615,386.043732 231.069077,385.918488 231.375000,385.610229 
	C231.561462,385.427246 231.902008,385.651398 232.051483,385.789978 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M200.998779,372.998779 
	C200.620621,372.506378 200.242462,372.013977 199.151062,370.592896 
	C204.067215,372.222107 207.941360,373.505981 212.039276,374.710388 
	C212.263077,374.630951 212.146423,374.701935 212.228943,375.023010 
	C213.295181,375.744415 214.278915,376.144714 215.522186,376.616943 
	C216.161484,376.467926 216.541275,376.247040 216.921051,376.026184 
	C217.129745,375.987946 217.338455,375.949707 217.858856,375.845367 
	C218.170547,375.779297 218.137985,375.791321 218.084457,376.181702 
	C216.334137,378.082458 214.637329,379.592834 212.751007,381.296143 
	C212.376297,381.995453 212.191101,382.501862 211.909790,383.085663 
	C211.813660,383.163086 211.613708,383.307861 211.434540,383.249268 
	C211.255356,383.190674 210.970657,382.943542 210.777115,382.759735 
	C210.269806,382.407867 209.956024,382.239838 209.310440,382.047791 
	C208.318542,381.953461 207.658463,381.883148 207.051758,381.518433 
	C207.064667,380.134918 207.024170,379.045776 206.983673,377.956635 
	C205.223938,376.348877 203.464203,374.741119 201.352234,373.066711 
	C201.000000,373.000000 200.998779,372.998779 200.998779,372.998779 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M139.003021,369.931183 
	C137.244644,369.205811 135.486267,368.480438 133.224579,367.419739 
	C132.497986,366.558960 132.274719,366.033569 132.051483,365.508148 
	C132.547989,363.822388 133.044510,362.136627 134.046188,360.236389 
	C134.977692,359.680298 135.404037,359.338654 135.830383,358.997009 
	C136.041504,358.965576 136.252655,358.934113 136.798798,358.937439 
	C137.133820,358.972290 136.976730,359.061646 136.984711,359.199768 
	C136.992706,359.337860 136.958527,359.612427 136.948303,360.056091 
	C137.790176,360.687256 138.642258,360.874756 139.501495,361.030334 
	C139.508652,360.998352 139.443390,360.992462 139.711578,361.293518 
	C141.009186,361.863159 142.038605,362.131714 143.072998,362.556458 
	C143.077988,362.712677 143.092758,363.024841 143.092758,363.024841 
	C143.092758,363.024841 143.042984,363.417633 142.708054,363.634277 
	C141.948227,363.856903 141.523346,363.862823 140.841034,363.606262 
	C139.377380,363.241882 138.171127,363.140045 136.984955,362.766357 
	C136.621155,362.445435 136.237289,362.396332 135.853409,362.347229 
	C136.243286,362.556702 136.633179,362.766174 137.259232,363.225891 
	C138.596832,363.730469 139.698288,363.984741 140.816315,364.436035 
	C140.832886,364.633026 140.975723,365.001678 140.737122,365.241882 
	C140.000031,366.965118 139.501526,368.448151 139.003021,369.931183 
M137.974533,365.491821 
	C137.974533,365.491821 138.015137,365.467865 137.974533,365.491821 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M139.272430,370.138489 
	C139.501526,368.448151 140.000031,366.965118 141.062500,365.270020 
	C142.055099,365.129242 142.483749,365.200562 142.727234,365.447937 
	C142.262497,366.160736 141.982925,366.697418 141.703369,367.234131 
	C142.468918,367.156097 143.234451,367.078064 144.072540,367.289978 
	C144.735458,368.032715 145.325836,368.485474 145.916214,368.938263 
	C147.105469,371.811462 148.634323,374.730835 144.348785,376.670959 
	C143.236374,375.312408 142.605774,374.208252 142.282959,373.064636 
	C142.777496,372.729431 142.964264,372.433746 143.151031,372.138000 
	C142.588074,372.079529 142.025101,372.021057 141.489838,371.963501 
	C141.517517,371.964447 141.569016,371.944031 141.569016,371.944031 
	C140.893295,371.411285 140.217575,370.878510 139.272430,370.138489 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M181.625763,366.460999 
	C179.997711,367.257172 178.639450,367.993408 176.875046,368.949768 
	C176.624786,368.281891 176.359375,367.573486 176.087936,366.849030 
	C170.908035,368.056519 170.774460,367.963196 170.054626,362.499695 
	C172.909744,362.975281 175.812576,363.275299 178.596497,364.019836 
	C179.820892,364.347290 180.803268,365.579590 181.625763,366.460999 
M173.154388,365.515015 
	C173.154388,365.515015 172.882812,365.426880 172.882812,365.426880 
	C172.882812,365.426880 173.054245,365.624756 173.154388,365.515015 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M150.578430,380.232422 
	C157.631805,380.837799 164.943573,376.242004 171.890289,381.642212 
	C172.004120,381.998810 171.959717,382.053131 171.959717,382.053131 
	C166.570023,382.038330 161.180328,382.023499 155.051971,381.978882 
	C153.068344,381.376892 151.823380,380.804657 150.578430,380.232422 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M141.218414,372.188416 
	C141.569016,371.944031 141.517517,371.964447 141.578979,372.266479 
	C141.752029,372.747040 141.863602,372.925568 141.975174,373.104126 
	C142.605774,374.208252 143.236374,375.312408 143.972351,376.722656 
	C144.077713,377.028778 144.044754,377.375366 143.850708,377.764893 
	C144.781738,378.858246 145.906830,379.562134 146.816528,380.428406 
	C145.892578,380.710999 145.184006,380.831177 144.250839,380.667694 
	C143.383331,380.408295 142.740448,380.432556 142.162552,380.055511 
	C140.930237,378.803711 139.632919,377.953247 138.163864,376.999756 
	C137.719986,376.843231 137.450989,376.776978 137.303314,376.292908 
	C136.523361,374.874878 135.625183,373.861908 134.727005,372.848938 
	C136.773941,372.710236 138.820862,372.571533 141.218414,372.188416 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M206.671875,378.030762 
	C207.024170,379.045776 207.064667,380.134918 206.829361,381.632141 
	C206.254242,382.252930 206.116531,382.542511 205.781921,382.920013 
	C204.945557,382.951202 204.467682,382.971344 203.725739,382.982544 
	C203.286072,382.960205 203.110443,382.946777 202.934845,382.933350 
	C201.627747,381.156464 200.320648,379.379608 199.013550,377.602722 
	C198.717712,377.924896 198.421875,378.247040 198.126053,378.569183 
	C200.870712,378.414398 203.615387,378.259644 206.671875,378.030762 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M172.026871,381.972107 
	C173.803818,382.108307 175.558029,382.271210 177.312225,382.434113 
	C177.542923,382.977386 177.773621,383.520660 178.004303,384.063965 
	C176.115662,384.959015 174.227005,385.854065 172.338364,386.749115 
	C172.265030,385.340027 172.191696,383.930969 172.039032,382.287506 
	C171.959717,382.053131 172.004120,381.998810 172.026871,381.972107 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M212.256927,374.562927 
	C208.619446,372.171753 204.988098,369.848572 201.356735,367.525421 
	C201.615707,367.124542 201.874680,366.723663 202.133667,366.322784 
	C206.078735,368.997528 210.023804,371.672272 213.980042,374.678772 
	C213.991196,375.010529 214.012589,374.985901 213.699127,374.948181 
	C212.972595,374.840942 212.559509,374.771423 212.146423,374.701904 
	C212.146423,374.701935 212.263077,374.630951 212.256927,374.562927 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M155.020172,395.019012 
	C153.966675,393.932404 152.895035,392.832336 151.841583,391.385101 
	C154.278000,390.754456 156.696228,390.470947 160.225403,390.057190 
	C158.473404,391.962891 157.433670,393.093872 155.933685,394.501801 
	C155.304062,394.845551 155.134659,394.912384 154.965240,394.979218 
	C154.965240,394.979218 155.002029,395.005524 155.020172,395.019012 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M144.475449,380.951355 
	C145.184006,380.831177 145.892578,380.710999 147.074310,380.365814 
	C147.714233,380.079895 147.880981,380.018982 148.047714,379.958069 
	C148.047714,379.958069 148.002670,379.996368 148.098053,380.273499 
	C149.489471,381.257843 150.785507,381.965027 152.000793,382.871490 
	C152.335007,383.378693 152.749924,383.686646 153.164856,383.994598 
	C152.268906,384.391266 151.372971,384.787933 150.001144,384.898865 
	C148.695114,384.653198 147.864975,384.693268 146.549622,384.719604 
	C145.744156,384.752045 145.423874,384.798187 145.125519,384.845825 
	C145.147446,384.847321 145.138977,384.888885 145.095490,384.486603 
	C144.859818,383.040009 144.667633,381.995667 144.475449,380.951355 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M202.650879,382.805237 
	C203.110443,382.946777 203.286072,382.960205 203.706329,383.332153 
	C203.259918,384.537628 202.568893,385.384583 201.865250,386.291321 
	C201.852631,386.351105 201.731430,386.335785 201.749878,386.063049 
	C201.563736,385.443726 201.359131,385.097168 201.154526,384.750610 
	C200.773254,385.086670 200.391998,385.422729 199.743835,385.809570 
	C199.300095,385.899506 199.123245,385.938690 198.946411,385.977875 
	C198.946411,385.977875 199.008865,385.983978 198.887924,385.718536 
	C198.177200,385.204712 197.587418,384.956329 196.997635,384.707916 
	C197.490982,380.603333 199.848801,381.428253 202.650879,382.805237 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M151.003586,372.996338 
	C153.560303,372.193787 156.117035,371.391266 158.673752,370.588715 
	C158.834900,371.301910 158.996048,372.015106 159.157196,372.728333 
	C156.616089,372.958527 154.074997,373.188721 151.266953,373.209442 
	C151.000000,373.000000 151.003586,372.996338 151.003586,372.996338 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M148.099457,389.485718 
	C146.678040,389.257812 145.248520,388.914185 143.384399,388.593506 
	C142.949799,388.616455 142.999649,388.549988 142.865631,388.225220 
	C142.147736,387.591217 141.563873,387.281982 140.980011,386.972778 
	C140.979996,386.972778 140.998993,387.002167 141.080109,386.750549 
	C141.114105,386.321014 141.066986,386.143036 141.019867,385.965088 
	C141.019867,385.965088 140.996872,385.997528 141.155411,385.955200 
	C141.549500,385.863495 141.776154,385.787567 142.519684,385.709167 
	C143.346069,385.552307 143.646667,385.371307 144.117401,385.251648 
	C144.872940,385.608887 145.458374,385.904816 146.008575,386.372040 
	C145.973358,386.543365 145.874863,386.879028 145.806671,387.222473 
	C146.468918,388.111786 147.199341,388.657623 147.929749,389.203461 
	C147.929764,389.203461 148.091370,389.369995 148.099457,389.485718 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M184.999084,382.001587 
	C183.713608,381.513123 182.428146,381.024628 179.866501,380.051178 
	C182.518280,379.080963 183.903000,378.574341 185.757431,377.895844 
	C185.451263,379.355530 185.238678,380.369110 185.013031,381.691345 
	C185.000000,382.000000 184.999084,382.001587 184.999084,382.001587 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M199.063782,386.300171 
	C199.123245,385.938690 199.300095,385.899506 200.026093,385.922333 
	C200.960632,386.101471 201.346039,386.218628 201.731430,386.335785 
	C201.731430,386.335785 201.852631,386.351105 202.089554,386.521912 
	C203.745026,387.223785 205.163574,387.754852 206.582138,388.285919 
	C206.969940,388.678955 207.357742,389.071991 207.790466,389.821716 
	C207.835403,390.178375 207.584442,390.474274 207.584442,390.474304 
	C206.974884,390.251984 206.365326,390.029663 205.443024,389.719604 
	C203.147247,388.628723 201.164200,387.625610 199.063782,386.300171 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M149.939438,367.958771 
	C152.258423,367.457214 154.577408,366.955688 156.896378,366.454132 
	C156.981812,367.107635 157.067245,367.761139 157.152679,368.414642 
	C155.048828,368.292328 152.944992,368.169983 150.418213,368.028198 
	C149.995270,368.008789 149.939438,367.958771 149.939438,367.958771 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M132.016052,353.014130 
	C133.048569,352.725952 134.081085,352.437775 135.458221,352.182373 
	C137.331177,352.744019 138.859497,353.272919 139.780655,353.964600 
	C138.103241,354.067505 137.032974,354.007629 135.715057,353.906372 
	C135.304276,353.904022 135.141159,353.943024 134.994598,353.994019 
	C135.011169,354.006042 135.044556,354.029724 134.883575,353.726013 
	C133.820419,353.286255 132.918228,353.150177 132.016052,353.014130 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M189.843842,370.901062 
	C188.469742,371.167633 187.131866,371.167633 185.793991,371.167633 
	C185.854416,370.594543 185.914856,370.021484 185.975281,369.448395 
	C187.276871,369.843750 188.578476,370.239136 189.843842,370.901062 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M185.001007,381.998779 
	C186.765915,381.997559 188.529831,381.997559 190.293747,381.997559 
	C190.233337,382.618347 190.172943,383.239136 190.112534,383.859955 
	C188.573608,383.384399 187.034698,382.908844 185.247437,382.217468 
	C184.999084,382.001587 185.000000,382.000000 185.001007,381.998779 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M194.145874,371.752930 
	C195.315491,371.905365 196.302704,372.237793 197.289917,372.570251 
	C197.099670,373.020538 196.909439,373.470856 196.719193,373.921173 
	C195.800613,373.258423 194.882034,372.595673 194.145874,371.752930 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M152.081528,382.672241 
	C150.785507,381.965027 149.489471,381.257843 148.098877,380.243958 
	C148.648743,379.992371 149.293137,380.047455 150.257996,380.167480 
	C151.823380,380.804657 153.068344,381.376892 154.655823,381.975708 
	C155.043350,382.271423 155.088333,382.540527 154.828491,383.109924 
	C154.354904,383.386078 154.186127,383.361969 153.692902,383.233765 
	C152.939499,382.977234 152.510513,382.824738 152.081528,382.672241 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M142.912399,365.271851 
	C142.483749,365.200562 142.055099,365.129242 141.301086,365.029816 
	C140.975723,365.001678 140.832886,364.633026 140.891632,364.342529 
	C140.950394,364.052032 141.098450,363.868774 141.098450,363.868774 
	C141.523346,363.862823 141.948227,363.856903 142.691254,363.831543 
	C144.682709,364.429016 146.356033,365.045929 147.665741,365.929138 
	C146.847565,366.206421 146.393021,366.217377 145.624542,366.156250 
	C144.887833,366.010834 144.465042,365.937531 143.896545,365.694794 
	C143.505402,365.328308 143.225922,365.243774 142.912399,365.271851 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M196.753082,384.668274 
	C197.587418,384.956329 198.177200,385.204712 198.908752,385.742004 
	C197.247375,385.669922 195.444214,385.308960 193.641052,384.947998 
	C193.703094,384.655518 193.765137,384.363007 193.827179,384.070526 
	C194.720963,384.256561 195.614761,384.442596 196.753082,384.668274 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M154.603806,363.340607 
	C154.237076,364.079407 153.616730,364.740601 152.996384,365.401794 
	C152.764511,365.041382 152.532639,364.680969 152.300766,364.320587 
	C152.983902,363.968048 153.667038,363.615540 154.603806,363.340607 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M145.874863,386.879028 
	C145.874863,386.879028 145.973358,386.543365 145.871063,385.964111 
	C145.547043,385.204681 145.325317,385.024506 145.103607,384.844360 
	C145.423874,384.798187 145.744156,384.752045 146.835175,384.799316 
	C147.993057,384.958160 148.380234,385.023529 148.767395,385.088898 
	C148.405884,385.724060 148.044388,386.359222 147.128693,387.093994 
	C146.294174,387.193604 146.060959,387.088745 145.874863,386.879028 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M147.907074,379.651550 
	C147.880981,380.018982 147.714233,380.079895 147.289703,380.203430 
	C145.906830,379.562134 144.781738,378.858246 143.834564,377.938232 
	C145.263794,378.263031 146.515106,378.804016 147.907074,379.651550 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M145.938477,366.228333 
	C146.393021,366.217377 146.847565,366.206421 147.643600,366.192139 
	C147.404175,366.946960 146.823242,367.705139 146.079269,368.700806 
	C145.325836,368.485474 144.735458,368.032715 144.335968,367.251465 
	C144.997391,366.691437 145.467926,366.459869 145.938477,366.228333 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M149.000229,385.073547 
	C148.380234,385.023529 147.993057,384.958160 147.320358,384.813049 
	C147.864975,384.693268 148.695114,384.653198 149.612030,384.820862 
	C149.698792,385.028564 149.233063,385.058197 149.000229,385.073547 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M168.732697,388.623596 
	C168.259659,388.374512 168.010757,388.069031 167.761856,387.763550 
	C168.160904,387.652069 168.559937,387.540619 168.958984,387.429138 
	C168.958267,387.808472 168.957550,388.187836 168.732697,388.623596 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M157.752533,364.826416 
	C158.428619,364.446228 159.063065,364.290405 159.697525,364.134583 
	C159.729523,364.440002 159.761520,364.745392 159.793518,365.050812 
	C159.099319,365.050812 158.405106,365.050812 157.752533,364.826416 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M139.443390,360.992462 
	C139.443390,360.992462 139.508652,360.998352 139.406342,360.772583 
	C139.246399,360.373138 139.188751,360.199402 139.304092,359.860596 
	C139.687195,359.453796 139.871002,359.193481 140.004028,358.730469 
	C139.979553,358.546387 139.954636,358.175812 139.954636,358.175812 
	C140.376526,358.881866 140.798401,359.587921 140.720337,360.609283 
	C139.961380,360.947205 139.702377,360.969849 139.443390,360.992462 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M201.591431,363.797241 
	C202.256668,363.675446 203.051910,363.815399 203.847168,363.955322 
	C203.776962,364.221863 203.706772,364.488434 203.636566,364.754974 
	C202.998184,364.522980 202.359818,364.290985 201.591431,363.797241 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M147.857300,363.476013 
	C147.451187,363.179596 147.252411,362.847351 147.053635,362.515137 
	C147.331787,362.458252 147.609940,362.401367 147.888092,362.344482 
	C147.946945,362.709747 148.005798,363.074951 147.857300,363.476013 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M164.054855,389.941528 
	C164.969681,390.418396 165.884506,390.895264 166.902634,391.682556 
	C167.005936,391.992981 166.999008,392.000763 166.665436,392.002014 
	C165.891632,392.004639 165.451401,392.006042 165.011154,392.007446 
	C165.011154,392.007446 165.001343,392.000854 164.995117,391.996674 
	C164.724304,391.513489 164.459686,391.034485 164.092773,390.282715 
	C163.990448,390.009918 164.054855,389.941528 164.054855,389.941528 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M201.000519,373.000610 
	C200.740997,373.681061 200.480988,374.360931 200.220978,375.040771 
	C199.965378,374.912689 199.709778,374.784607 199.454163,374.656525 
	C199.791931,374.153259 200.129684,373.650024 200.733109,373.072754 
	C200.998779,372.998779 201.000000,373.000000 201.000519,373.000610 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M173.014145,375.191711 
	C172.631989,375.419739 172.240540,375.446716 171.849091,375.473694 
	C171.852890,375.208862 171.856689,374.944000 171.860489,374.679169 
	C172.241943,374.782990 172.623398,374.886810 173.014145,375.191711 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M143.334991,362.948059 
	C143.092758,363.024841 143.077988,362.712677 143.093292,362.205292 
	C143.068268,361.467560 143.027924,361.237213 142.987579,361.006897 
	C143.184143,361.628357 143.380692,362.249786 143.334991,362.948059 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M193.960464,400.394714 
	C193.787628,400.144073 193.778488,399.897644 193.769348,399.651245 
	C193.908371,399.655060 194.047394,399.658875 194.186417,399.662659 
	C194.165665,399.908081 194.144913,400.153534 193.960464,400.394714 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M152.263245,376.314148 
	C151.967072,376.255249 151.788940,376.086945 151.610794,375.918610 
	C151.711960,375.825439 151.813141,375.732239 151.914307,375.639069 
	C152.069962,375.827606 152.225616,376.016174 152.263245,376.314148 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M164.038666,375.592896 
	C164.214783,375.847504 164.224258,376.098114 164.233749,376.348694 
	C164.092361,376.345032 163.950989,376.341370 163.809601,376.337677 
	C163.830414,376.088074 163.851227,375.838440 164.038666,375.592896 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M164.057678,372.605225 
	C164.228485,372.852020 164.237717,373.094971 164.246933,373.337891 
	C164.109924,373.334625 163.972931,373.331360 163.835938,373.328125 
	C163.855988,373.085876 163.876038,372.843658 164.057678,372.605225 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M216.617050,375.906921 
	C216.541275,376.247040 216.161484,376.467926 215.344513,376.426544 
	C214.609070,375.771484 214.310822,375.378693 214.012589,374.985901 
	C214.012589,374.985901 213.991196,375.010529 213.979462,375.024658 
	C214.749496,375.288391 215.531281,375.538025 216.617050,375.906921 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M188.629944,393.855377 
	C188.982635,393.957489 189.166306,394.121582 189.349960,394.285645 
	C188.981293,394.401611 188.612625,394.517578 188.243958,394.633545 
	C188.316269,394.394806 188.388580,394.156067 188.629944,393.855377 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M151.033005,371.031799 
	C151.023926,371.468353 151.014847,371.904938 151.004669,372.668915 
	C151.003586,372.996338 151.000000,373.000000 150.998230,373.001526 
	C150.821899,372.374207 150.647324,371.745361 150.738739,371.059448 
	C151.004730,371.002380 151.033005,371.031799 151.033005,371.031799 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M183.026215,387.607483 
	C183.195435,387.853546 183.204117,388.095184 183.212784,388.336853 
	C183.076553,388.332672 182.940323,388.328491 182.804108,388.324341 
	C182.824631,388.083893 182.845154,387.843506 183.026215,387.607483 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M194.753479,368.683655 
	C195.047928,368.741943 195.225037,368.909119 195.402130,369.076294 
	C195.301544,369.169342 195.200989,369.262421 195.100433,369.355530 
	C194.945663,369.167877 194.790909,368.980225 194.753479,368.683655 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M145.806671,387.222473 
	C146.060959,387.088745 146.294174,387.193604 146.768280,387.161072 
	C147.525192,387.765839 148.088348,388.403168 148.290619,389.121948 
	C147.199341,388.657623 146.468918,388.111786 145.806671,387.222473 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M135.962723,353.947754 
	C137.032974,354.007629 138.103241,354.067505 139.571426,354.184875 
	C139.778671,354.461700 139.562622,354.652283 138.770081,354.896851 
	C137.494827,354.932678 136.770691,354.885681 136.025818,354.616211 
	C136.005051,354.393738 135.962723,353.947723 135.962723,353.947754 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M163.958160,390.044006 
	C163.325150,389.983032 162.724442,389.887970 162.123734,389.792908 
	C162.175644,389.541107 162.227554,389.289337 162.279465,389.037537 
	C162.825058,389.191864 163.370636,389.346191 163.985535,389.721008 
	C164.054855,389.941528 163.990448,390.009918 163.958160,390.044006 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M136.046570,354.838745 
	C136.770691,354.885681 137.494827,354.932678 138.581284,355.002930 
	C138.943619,355.026245 139.003006,355.480560 138.951187,355.704041 
	C137.761948,355.783813 136.624542,355.640137 135.243530,355.249023 
	C134.999924,355.001587 134.972122,355.029602 135.244232,355.001648 
	C135.693100,354.928711 135.869827,354.883728 136.046570,354.838745 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M186.218109,400.835602 
	C186.290192,401.057709 186.220261,401.286926 186.150330,401.516113 
	C186.125595,401.291626 186.100830,401.067169 186.218109,400.835602 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M181.995026,400.992493 
	C182.299240,400.639923 182.599533,400.285339 182.931213,399.963074 
	C182.977234,399.918396 183.178177,400.033234 183.307373,400.074219 
	C183.024673,400.360504 182.741974,400.646729 182.237808,400.974060 
	C182.016342,401.015167 182.001053,401.000671 181.995026,400.992493 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M160.557678,397.093018 
	C160.935242,397.178223 161.013351,397.537628 160.903549,397.879028 
	C160.793747,398.220459 160.504059,398.576813 160.504059,398.576813 
	C159.745132,398.232025 158.986206,397.887238 158.095932,397.256775 
	C158.703094,396.983368 159.441589,396.995575 160.557678,397.093018 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M165.158356,365.363586 
	C165.446274,365.382141 165.645905,365.512299 165.845535,365.642456 
	C165.587036,365.586700 165.328552,365.530914 165.158356,365.363586 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M190.024887,367.017273 
	C189.640823,367.178253 189.256760,367.339264 188.872711,367.500244 
	C188.828766,367.276825 188.784805,367.053375 188.740845,366.829926 
	C189.024460,366.835144 189.308090,366.840363 189.796661,366.922699 
	C190.001602,366.999786 190.024887,367.017273 190.024887,367.017273 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M166.371307,363.226501 
	C166.556503,363.385498 166.601807,363.580505 166.647110,363.775543 
	C166.508560,363.604523 166.369995,363.433533 166.371307,363.226501 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M142.653717,360.984314 
	C143.027924,361.237213 143.068268,361.467560 143.088318,362.049072 
	C142.038605,362.131714 141.009186,361.863159 139.711578,361.293518 
	C139.702377,360.969849 139.961380,360.947205 140.603821,360.860718 
	C141.431458,360.851807 141.875671,360.906769 142.653717,360.984314 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M168.823944,374.632690 
	C168.544754,374.614044 168.351669,374.488342 168.158569,374.362671 
	C168.409058,374.416962 168.659546,374.471283 168.823944,374.632690 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M176.963120,377.964844 
	C177.036789,377.391754 177.110443,376.818634 177.184113,376.245544 
	C177.372284,376.287750 177.560455,376.329926 177.748627,376.372131 
	C177.619385,376.808044 177.490128,377.243927 177.180237,377.840088 
	C176.999603,378.000366 176.963120,377.964844 176.963120,377.964844 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M177.017395,378.018585 
	C176.819000,378.399078 176.602814,378.761353 176.386597,379.123627 
	C176.216919,378.941254 176.047241,378.758850 175.877548,378.576416 
	C176.102310,378.400848 176.327072,378.225281 176.757477,378.007263 
	C176.963120,377.964844 176.999603,378.000366 177.017395,378.018585 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M194.593994,381.385559 
	C194.680450,381.172577 194.863983,381.060455 195.047516,380.948303 
	C194.928696,381.127686 194.809891,381.307068 194.593994,381.385559 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M139.584167,358.140167 
	C139.954636,358.175812 139.979553,358.546387 139.621674,358.739777 
	C138.501434,358.975983 137.739090,359.018829 136.976730,359.061646 
	C136.976730,359.061646 137.133820,358.972290 137.114273,358.882629 
	C137.094727,358.792938 137.081604,358.500702 137.040863,358.361145 
	C137.737961,358.182556 138.475830,358.143555 139.584167,358.140167 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M162.832581,386.171326 
	C163.073654,386.072662 163.294449,386.115784 163.515244,386.158875 
	C163.280945,386.210297 163.046631,386.261688 162.832581,386.171326 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M168.413177,384.104065 
	C168.139343,384.034546 167.973526,383.868988 167.807709,383.703400 
	C168.045532,383.804932 168.283356,383.906494 168.413177,384.104065 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M152.000793,382.871490 
	C152.510513,382.824738 152.939499,382.977234 153.681076,383.393616 
	C153.993668,383.657532 154.001877,383.979523 154.001877,383.979523 
	C154.001877,383.979523 153.582581,383.988770 153.373718,383.991699 
	C152.749924,383.686646 152.335007,383.378693 152.000793,382.871490 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M176.002838,401.004852 
	C175.755341,401.189056 175.504120,401.367523 175.252914,401.546021 
	C175.384872,401.376221 175.516830,401.206451 175.818298,401.012573 
	C175.987793,400.988525 175.999146,400.999146 176.002838,401.004852 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M186.121658,396.458923 
	C186.156219,396.531036 185.852112,396.551697 185.852112,396.551697 
	C185.852112,396.551697 186.087097,396.386780 186.121658,396.458923 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M187.989319,398.366882 
	C188.068558,398.368073 187.984436,398.624817 187.984436,398.624817 
	C187.984436,398.624817 187.910095,398.365662 187.989319,398.366882 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M158.074966,387.048615 
	C157.756058,387.180573 157.437164,387.312531 157.118256,387.444489 
	C157.279449,387.294922 157.440659,387.145355 157.806702,386.995178 
	C158.011551,386.994598 158.074966,387.048615 158.074966,387.048615 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M182.112488,377.575348 
	C182.059616,377.634888 181.875793,377.422333 181.875793,377.422333 
	C181.875793,377.422333 182.165359,377.515808 182.112488,377.575348 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M150.992783,370.985962 
	C151.241974,370.790344 151.503113,370.611206 151.764267,370.432068 
	C151.635376,370.613678 151.506485,370.795319 151.205292,371.004364 
	C151.033005,371.031799 151.004730,371.002380 150.992783,370.985962 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M212.657440,370.407166 
	C212.041138,370.276428 211.568268,369.982208 211.122910,369.246918 
	C211.269363,368.654633 211.388275,368.503448 211.507172,368.352295 
	C211.952316,368.698853 212.397446,369.045410 212.959595,369.649658 
	C213.076630,369.907349 212.800842,370.243683 212.657440,370.407166 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M208.476151,367.560516 
	C207.999344,367.258301 207.522537,366.956085 206.050812,366.023224 
	C207.722046,366.265594 208.245956,366.341553 208.767685,366.824463 
	C208.765518,367.231384 208.476151,367.560516 208.476151,367.560516 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M155.012756,395.339386 
	C155.134659,394.912384 155.304062,394.845551 155.744446,394.743927 
	C156.015442,394.709106 156.458008,394.916901 156.744598,395.218781 
	C157.030457,395.691162 157.029724,395.861694 157.028976,396.032227 
	C156.372742,395.921326 155.716507,395.810425 155.012756,395.339386 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M154.177277,383.944214 
	C154.001877,383.979523 153.993668,383.657532 154.005493,383.497681 
	C154.186127,383.361969 154.354904,383.386078 154.757538,383.513000 
	C154.801544,383.763702 154.588638,383.861389 154.177277,383.944214 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M153.492813,386.489166 
	C153.513748,386.480804 153.471878,386.497528 153.492813,386.489166 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M210.957596,369.020386 
	C210.457077,369.105835 210.149399,368.976715 209.846649,368.493378 
	C209.851578,368.139160 210.191467,367.794464 210.191467,367.794464 
	C210.519531,367.820221 210.828384,367.911194 211.312607,368.209839 
	C211.388275,368.503448 211.269363,368.654633 210.957596,369.020386 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M150.020050,368.036926 
	C150.044815,368.065063 149.813950,368.303772 149.813950,368.303772 
	C149.813950,368.303772 149.799255,368.063965 149.869354,368.011353 
	C149.939438,367.958771 149.995270,368.008789 150.020050,368.036926 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M208.923187,367.079224 
	C209.417557,366.978546 209.703049,367.127930 210.064392,367.584839 
	C210.191467,367.794464 209.851578,368.139160 209.675446,368.308533 
	C209.239288,368.310272 208.998306,368.118652 208.626282,367.731781 
	C208.476151,367.560516 208.765518,367.231384 208.923187,367.079224 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M189.992691,366.988403 
	C189.983780,366.977020 190.159790,366.745422 190.159790,366.745422 
	C190.159790,366.745422 190.155762,366.933899 190.090332,366.975586 
	C190.024887,367.017273 190.001602,366.999786 189.992691,366.988403 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M205.337814,365.638977 
	C205.303528,365.380066 205.424988,365.207214 205.532990,365.215271 
	C205.640320,365.223236 205.734116,365.413086 205.833862,365.523163 
	C205.718460,365.616882 205.603058,365.710602 205.337814,365.638977 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M158.485718,362.509155 
	C158.475922,362.485565 158.495529,362.532776 158.485718,362.509155 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M157.015045,396.017395 
	C157.029724,395.861694 157.030457,395.691162 156.983795,395.269348 
	C157.219391,395.133240 157.477646,395.289398 157.606323,395.669678 
	C157.325241,395.893036 157.148972,395.933289 156.972687,395.973572 
	C156.972687,395.973572 157.001099,396.002594 157.015045,396.017395 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M156.908173,396.294769 
	C157.148972,395.933289 157.325241,395.893036 157.771362,395.825653 
	C158.149582,396.003571 158.257950,396.208679 158.200455,396.719727 
	C157.637604,396.889038 157.240631,396.752502 156.908173,396.294769 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M180.492371,394.488861 
	C180.515381,394.479584 180.469345,394.498138 180.492371,394.488861 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M157.983429,386.963867 
	C157.955292,386.933136 158.181946,386.712128 158.181946,386.712128 
	C158.181946,386.712128 158.203430,386.942139 158.139191,386.995361 
	C158.074966,387.048615 158.011551,386.994598 157.983429,386.963867 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M230.622803,386.025757 
	C231.059174,386.435272 231.288208,386.701569 231.726410,387.133026 
	C231.778610,387.512451 231.586639,387.688568 231.003845,388.025024 
	C230.769241,388.576843 230.890488,388.930176 231.011734,389.283539 
	C231.128235,389.518616 231.300018,389.701843 231.906921,390.114014 
	C232.868866,390.257782 233.450943,390.120697 234.033035,389.983643 
	C234.808105,390.181885 235.583160,390.380127 236.649323,390.929871 
	C236.974686,391.534943 237.008926,391.788544 236.783859,392.034424 
	C236.552750,392.506439 236.580963,392.986206 236.676956,393.628540 
	C236.744751,393.791138 236.973633,394.058929 236.973633,394.058929 
	C236.481140,394.598206 235.988647,395.137451 235.003052,395.697113 
	C233.997971,395.465302 233.486008,395.213074 232.974030,394.960876 
	C232.974030,394.960876 233.010239,394.989166 232.776733,394.768616 
	C231.684296,394.357819 230.825363,394.167633 229.966446,393.977417 
	C229.966446,393.977417 229.995697,394.006256 230.019150,393.701874 
	C229.362671,392.591614 228.682724,391.785736 227.915894,390.873779 
	C227.599670,390.574066 227.353561,390.559601 226.779861,390.758301 
	C226.270065,390.854156 226.071045,390.915985 225.872040,390.977814 
	C225.462891,390.992249 225.053726,391.006683 224.321960,391.010437 
	C223.999329,390.999756 223.967728,390.967560 223.991486,390.649353 
	C224.505096,389.528717 225.023697,388.020477 225.480179,388.039062 
	C228.311768,388.154266 228.065018,386.285309 228.084045,384.500854 
	C228.508255,384.637573 228.932465,384.774292 229.689362,384.942993 
	C230.153198,385.277496 230.284348,385.579987 230.622803,386.025757 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M203.038986,407.063904 
	C201.384308,405.994690 199.729614,404.925446 197.332809,403.376709 
	C199.313004,402.442993 201.429260,400.601379 203.141693,400.921295 
	C204.373337,401.151398 205.066162,404.265350 205.630676,406.548584 
	C204.520996,407.024139 203.779999,407.044037 203.038986,407.063904 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M206.738235,388.118042 
	C205.163574,387.754852 203.745026,387.223785 202.102173,386.462158 
	C202.568893,385.384583 203.259918,384.537628 203.970367,383.341064 
	C204.467682,382.971344 204.945557,382.951202 206.126724,382.955048 
	C208.043808,383.578064 209.257599,384.177124 210.471390,384.776184 
	C209.279037,385.834167 208.086685,386.892151 206.738235,388.118042 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M221.692245,413.371918 
	C219.433075,412.885895 217.173920,412.399872 214.523560,411.929199 
	C213.763535,411.474548 213.394699,411.004578 213.030029,410.253113 
	C213.034180,409.971649 213.007416,410.013824 213.007416,410.013824 
	C214.731720,410.371246 216.456024,410.728699 218.555405,411.145630 
	C219.861771,411.598663 220.793045,411.992218 221.817261,412.748993 
	C221.910187,413.112213 221.692245,413.371918 221.692245,413.371918 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M181.819519,413.151184 
	C181.501633,413.195709 181.238480,413.160065 181.126221,413.089355 
	C180.709824,412.290344 180.405655,411.562042 179.519196,409.439484 
	C181.739365,410.724396 182.928650,411.412689 184.117935,412.100983 
	C183.457764,412.436218 182.797577,412.771454 181.819519,413.151184 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M210.717529,384.826477 
	C209.257599,384.177124 208.043808,383.578064 206.485199,382.943970 
	C206.116531,382.542511 206.254242,382.252930 206.775970,381.926514 
	C207.658463,381.883148 208.318542,381.953461 209.484833,382.260132 
	C210.317581,382.645538 210.644119,382.794525 210.970673,382.943542 
	C210.970657,382.943542 211.255356,383.190674 211.577194,383.449158 
	C212.154327,383.913818 212.396881,384.134033 212.458282,384.539307 
	C212.289902,384.710327 211.953354,385.052673 211.953354,385.052673 
	C211.623459,384.994049 211.293564,384.935425 210.717529,384.826477 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M212.947754,393.068695 
	C211.336884,392.332581 209.725998,391.596436 207.849792,390.667297 
	C207.584442,390.474274 207.835403,390.178375 207.967270,390.035889 
	C209.747147,390.592712 211.395172,391.291992 213.148621,392.354553 
	C213.254044,392.717834 212.947754,393.068695 212.947754,393.068695 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M203.251526,407.342651 
	C203.779999,407.044037 204.520996,407.024139 205.629852,406.955017 
	C206.955627,407.314606 207.913574,407.723389 209.025223,408.449860 
	C209.178925,408.767517 208.976685,409.035522 208.976685,409.035522 
	C207.139145,408.564148 205.301605,408.092773 203.251526,407.342651 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M225.909515,391.209564 
	C226.071045,390.915985 226.270065,390.854156 227.000000,390.850342 
	C227.530884,390.908386 228.002792,390.979858 228.002792,390.979858 
	C228.682724,391.785736 229.362671,392.591614 230.033127,393.716919 
	C228.901733,393.399139 227.779831,392.761902 226.355377,392.011719 
	C226.052811,391.898743 225.947006,391.441315 225.909515,391.209564 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M166.482849,400.308533 
	C167.821167,400.966583 169.159470,401.624603 170.497772,402.282654 
	C170.386490,402.537659 170.275192,402.792664 170.163910,403.047668 
	C168.891663,402.563202 167.619400,402.078705 166.276062,401.165680 
	C166.297623,400.594269 166.390244,400.451385 166.482849,400.308533 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M208.716156,393.832458 
	C209.339645,394.144714 209.753860,394.524414 210.168091,394.904144 
	C209.832977,395.096191 209.497864,395.288239 209.162750,395.480255 
	C208.944138,394.953491 208.725510,394.426697 208.716156,393.832458 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M214.998489,387.003082 
	C214.098373,386.524841 213.198242,386.046631 212.125732,385.310547 
	C211.953354,385.052673 212.289902,384.710327 212.718292,384.709564 
	C213.704819,384.985352 214.262939,385.261902 214.939270,385.790253 
	C215.098282,386.159393 215.190002,386.286621 215.169449,386.391998 
	C215.125839,386.615662 215.023758,386.827942 214.958755,387.034241 
	C214.971832,387.023590 214.998489,387.003082 214.998489,387.003082 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M198.344604,392.843994 
	C198.570770,392.471985 198.862015,392.294556 199.153259,392.117126 
	C199.248978,392.380707 199.344696,392.644348 199.440414,392.907959 
	C199.096832,392.951508 198.753250,392.995026 198.344604,392.843994 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M177.263199,409.324463 
	C176.952469,409.263184 176.765396,409.087646 176.578308,408.912079 
	C176.684311,408.814209 176.790329,408.716339 176.896362,408.618469 
	C177.059860,408.815704 177.223358,409.012939 177.263199,409.324463 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M171.189728,406.427795 
	C171.055191,406.732422 170.782547,406.916901 170.509888,407.101379 
	C170.404068,406.865814 170.298248,406.630219 170.192444,406.394653 
	C170.478836,406.365662 170.765228,406.336670 171.189728,406.427795 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M220.922363,391.088135 
	C220.060089,390.561371 219.197800,390.034576 218.156479,389.265808 
	C217.977448,389.023834 217.961090,389.041046 218.272797,388.923340 
	C219.229752,388.935486 219.874985,389.065369 220.659515,389.419250 
	C221.056183,389.882751 221.313583,390.122284 221.408737,390.543335 
	C221.246506,390.724823 220.922379,391.088135 220.922363,391.088135 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M213.421707,392.556183 
	C214.234818,392.777740 214.880280,393.160980 215.525726,393.544189 
	C215.472977,393.730530 215.420227,393.916840 215.367462,394.103180 
	C214.672775,393.902588 213.978073,393.701996 213.115570,393.285034 
	C212.947754,393.068695 213.254044,392.717834 213.421707,392.556183 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M209.280182,408.633606 
	C210.431671,408.841919 211.481918,409.184174 212.769775,409.770111 
	C213.007416,410.013824 213.034180,409.971649 213.051590,409.952637 
	C211.872192,409.809937 210.675369,409.686249 209.227631,409.299042 
	C208.976685,409.035522 209.178925,408.767517 209.280182,408.633606 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M162.488083,398.454803 
	C163.255234,398.748474 164.022369,399.042145 164.820923,399.748474 
	C164.737381,400.292023 164.622452,400.422882 164.507507,400.553772 
	C163.758636,400.234985 163.009781,399.916168 162.224380,399.200195 
	C162.187836,398.803070 162.488083,398.454803 162.488083,398.454803 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M224.014954,391.016174 
	C223.494003,391.510529 222.957413,391.988434 222.453278,392.220032 
	C222.638474,391.654694 222.791229,391.335663 223.199921,391.003662 
	C223.626495,390.982971 223.797119,390.975281 223.967728,390.967560 
	C223.967728,390.967560 223.999329,390.999756 224.014954,391.016174 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M165.131882,392.212280 
	C165.451401,392.006042 165.891632,392.004639 166.662018,392.005249 
	C166.412308,392.143860 165.832458,392.280457 165.131882,392.212280 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M196.010468,406.618774 
	C195.937119,406.617401 196.015594,406.380890 196.015594,406.380890 
	C196.015594,406.380890 196.083801,406.620117 196.010468,406.618774 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M216.858185,388.156616 
	C216.340576,387.932922 215.822952,387.709229 215.151917,387.244324 
	C214.998489,387.003082 214.971832,387.023590 215.269348,386.927612 
	C216.136444,387.115936 216.705994,387.400269 217.171448,387.802795 
	C217.067337,387.921021 216.858185,388.156616 216.858185,388.156616 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M222.943985,391.016632 
	C222.791229,391.335663 222.638474,391.654694 222.234467,392.093140 
	C221.701935,391.998718 221.420685,391.784912 221.030914,391.329590 
	C220.922379,391.088135 221.246506,390.724823 221.625488,390.678528 
	C222.317642,390.760345 222.630814,390.888489 222.943985,391.016632 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M165.033447,399.972839 
	C165.531860,399.804321 165.831848,399.886902 166.298691,400.170380 
	C166.390244,400.451385 166.297623,400.594269 166.038071,400.930420 
	C165.547638,401.038208 165.224136,400.952667 164.704071,400.710449 
	C164.622452,400.422882 164.737381,400.292023 165.033447,399.972839 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M162.026352,398.966736 
	C161.542755,399.046478 161.220657,398.962494 160.701294,398.727661 
	C160.504059,398.576813 160.793747,398.220459 160.953949,398.055908 
	C161.439514,397.976471 161.764908,398.061615 162.289185,398.300781 
	C162.488083,398.454803 162.187836,398.803070 162.026352,398.966736 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M213.507294,395.512848 
	C213.486298,395.521362 213.528290,395.504333 213.507294,395.512848 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M190.486893,390.503845 
	C190.478653,390.482330 190.495132,390.525330 190.486893,390.503845 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M195.996277,389.994629 
	C195.992310,389.989258 196.157242,389.750031 196.157242,389.750031 
	C196.157242,389.750031 196.147247,389.932037 196.079437,389.970154 
	C196.011627,390.008240 196.000259,390.000000 195.996277,389.994629 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M217.984604,389.015442 
	C217.683945,388.879303 217.376114,388.751617 216.963242,388.390259 
	C216.858185,388.156616 217.067337,387.921021 217.427658,387.956635 
	C217.845688,388.341858 217.903397,388.691437 217.961090,389.041046 
	C217.961090,389.041046 217.977448,389.023834 217.984604,389.015442 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M233.761047,400.333862 
	C235.521378,401.712463 239.783401,400.970337 237.045685,405.223877 
	C236.208328,405.246399 235.445999,404.935638 234.671494,404.658966 
	C232.657837,403.939636 230.985992,402.961731 231.154388,400.206177 
	C230.989960,400.012512 230.953934,400.045868 230.953934,400.045837 
	C231.761520,400.084290 232.569107,400.122742 233.761047,400.333862 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M243.120239,418.115448 
	C244.552048,418.231232 245.983856,418.347015 247.415665,418.462769 
	C247.414581,418.774323 247.413513,419.085846 247.412445,419.397400 
	C243.510590,419.397400 239.608719,419.397400 235.299622,419.389252 
	C233.875488,419.253448 232.858597,419.125824 231.597900,418.706726 
	C231.926025,418.213104 232.497971,418.010864 233.069931,417.808655 
	C234.432434,417.914398 235.794922,418.020142 237.577072,418.056396 
	C237.996719,417.986877 237.945786,417.991608 238.156952,418.283386 
	C239.561234,418.689148 240.754349,418.803101 242.223709,418.903931 
	C242.681046,418.605225 242.862122,418.319702 243.051117,418.065247 
	C243.059067,418.096313 243.120239,418.115448 243.120239,418.115448 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M220.029373,417.729309 
	C220.351837,417.501434 220.674301,417.273590 221.608215,416.960327 
	C223.157532,416.930389 224.095413,416.985840 225.033295,417.041290 
	C225.033295,417.041290 225.010239,416.979889 225.116364,417.191437 
	C225.449341,417.648254 225.697403,417.869781 225.966675,418.067535 
	C225.966675,418.067535 225.947052,418.488342 225.541840,418.709412 
	C224.076828,418.941498 223.017014,418.952545 221.957199,418.963562 
	C221.957199,418.963562 221.982315,419.020660 221.885437,418.718262 
	C221.202148,418.187012 220.615753,417.958160 220.029373,417.729309 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M242.761505,421.759033 
	C242.563065,422.055664 242.081757,422.095428 241.247681,422.219391 
	C239.273209,422.165253 237.651474,422.026917 235.623260,421.863831 
	C237.637390,421.726746 240.057999,421.614441 242.761505,421.759033 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M222.095306,419.256897 
	C223.017014,418.952545 224.076828,418.941498 225.531906,418.919800 
	C224.695908,419.122833 223.464661,419.336517 222.095306,419.256897 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M233.070679,417.592041 
	C232.497971,418.010864 231.926025,418.213104 231.213562,418.687134 
	C230.482117,418.847351 229.891174,418.735718 229.315918,418.299438 
	C229.533249,417.648682 229.734863,417.322601 229.936493,416.996521 
	C230.728409,416.970917 231.520325,416.945343 232.677399,416.968567 
	C233.042542,417.017395 233.067184,416.942169 233.067184,416.942169 
	C233.067184,416.942169 233.071426,417.375427 233.070679,417.592041 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M229.521881,416.971649 
	C229.734863,417.322601 229.533249,417.648682 229.066284,418.122009 
	C228.094681,418.195099 227.388382,418.120911 226.324387,418.057129 
	C225.697403,417.869781 225.449341,417.648254 225.083786,417.195190 
	C226.332489,416.973877 227.719894,416.960327 229.521881,416.971649 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M241.615707,416.996185 
	C242.245148,417.244904 242.505035,417.483948 242.942581,417.919189 
	C243.120239,418.115448 243.059067,418.096313 242.771027,418.045776 
	C242.297546,418.021057 242.112137,418.046875 241.528549,418.069458 
	C240.068832,418.041351 239.007309,418.016479 237.945786,417.991608 
	C237.945786,417.991608 237.996719,417.986877 237.984833,418.008911 
	C239.064011,417.682831 240.155075,417.334686 241.615707,416.996185 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M233.073181,416.988892 
	C233.971344,416.604279 234.838852,416.248108 235.706375,415.891968 
	C235.796021,416.166504 235.885666,416.441071 235.975327,416.715607 
	C235.220398,416.800079 234.465469,416.884552 233.388870,416.955627 
	C233.067184,416.942169 233.042542,417.017395 233.073181,416.988892 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M224.977875,416.720947 
	C224.095413,416.985840 223.157532,416.930389 221.871902,416.824341 
	C222.656921,416.649353 223.789703,416.524963 224.977875,416.720947 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M219.732727,417.799194 
	C220.615753,417.958160 221.202148,418.187012 221.914688,418.707397 
	C221.172577,418.622345 220.304337,418.245697 219.732727,417.799194 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M232.939209,395.433502 
	C233.486008,395.213074 233.997971,395.465302 234.767334,395.843567 
	C234.473892,396.643738 233.923080,397.317841 233.161865,398.248779 
	C232.951477,398.505676 232.830597,398.300171 232.884201,398.120972 
	C232.937805,397.941772 232.995377,397.798676 232.995377,397.798676 
	C232.965042,397.167816 232.934708,396.536987 232.939209,395.433502 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M238.148758,408.422607 
	C238.892197,408.177917 239.591644,408.133362 240.635529,408.035522 
	C240.979965,407.982208 240.966476,407.970367 241.044342,408.243652 
	C241.239502,408.665710 241.356766,408.814484 241.474014,408.963226 
	C241.474014,408.963226 241.192368,409.159637 240.712067,409.132141 
	C239.478470,409.060669 238.725159,409.016724 237.985443,408.985901 
	C237.999023,408.999023 238.026505,409.025085 238.026505,409.025085 
	C238.026505,409.025085 238.104752,408.622772 238.148758,408.422607 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M229.968033,394.963593 
	C229.968033,394.963593 229.961609,395.455994 229.881897,395.835571 
	C229.802185,396.215118 229.562225,396.520966 229.562225,396.520966 
	C229.069565,396.201904 228.576904,395.882874 228.084229,395.563843 
	C228.549713,395.386017 229.015213,395.208191 229.749893,395.000793 
	C230.019073,394.971252 229.968033,394.963593 229.968033,394.963593 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M230.016617,394.236542 
	C230.825363,394.167633 231.684296,394.357819 232.795395,394.781982 
	C232.278397,395.001312 231.509216,394.986694 230.354034,394.967834 
	C229.968033,394.963593 230.019073,394.971252 230.021393,394.996521 
	C230.038055,394.846405 230.052414,394.671051 230.016617,394.236542 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M238.067871,410.037964 
	C237.342163,409.977478 236.616470,409.917023 235.890762,409.856567 
	C235.882050,409.679474 235.873337,409.502411 235.864639,409.325317 
	C236.383667,409.227570 236.902710,409.129791 237.724121,409.028564 
	C238.026505,409.025085 237.999023,408.999023 238.007172,409.252502 
	C238.018692,409.684296 238.022049,409.862610 238.030487,410.025177 
	C238.035553,410.009399 238.067871,410.037964 238.067871,410.037964 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M240.940781,409.512512 
	C240.943207,409.770813 240.945648,410.029114 240.969910,410.674316 
	C240.130249,410.908417 239.268784,410.755676 238.237579,410.320435 
	C238.067871,410.037964 238.035553,410.009399 238.402527,409.983582 
	C239.493240,409.809326 240.217010,409.660919 240.940781,409.512512 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M241.100067,411.268311 
	C241.681030,411.245453 242.410294,411.483337 243.025879,411.935638 
	C242.357590,411.943054 241.802979,411.736023 241.100067,411.268311 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M238.515564,412.090057 
	C238.671036,412.397797 238.574234,412.662445 238.477417,412.927094 
	C238.150223,412.761566 237.823044,412.596039 237.495850,412.430511 
	C237.751663,412.302673 238.007477,412.174835 238.515564,412.090057 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M222.020279,412.983307 
	C222.917007,412.986725 223.703644,413.119049 224.490295,413.251373 
	C224.478424,413.416962 224.466553,413.582581 224.454681,413.748169 
	C223.703400,413.748169 222.952133,413.748169 221.946548,413.560059 
	C221.692245,413.371918 221.910187,413.112213 222.020279,412.983307 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M214.747437,417.301514 
	C215.522675,417.190399 216.059433,417.261749 216.596176,417.333069 
	C216.553741,417.549103 216.511292,417.765137 216.468857,417.981171 
	C215.815552,417.815430 215.162262,417.649689 214.747437,417.301514 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M211.618851,416.308411 
	C212.069000,416.332733 212.309494,416.534973 212.549988,416.737213 
	C212.169724,416.653595 211.789459,416.569977 211.618851,416.308411 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M231.008850,399.996857 
	C230.905945,399.915863 230.784134,399.850525 230.662338,399.785217 
	C230.778229,399.763092 230.894119,399.740967 230.981964,399.882355 
	C230.953934,400.045868 230.989960,400.012512 231.008850,399.996857 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M232.856110,397.623688 
	C232.995377,397.798676 232.937805,397.941772 232.945862,398.018951 
	C232.733566,397.920441 232.513214,397.744720 232.292862,397.568970 
	C232.434189,397.528900 232.575516,397.488800 232.856110,397.623688 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M229.937347,396.075439 
	C230.288574,396.183746 230.504639,396.431763 230.720673,396.679779 
	C230.472321,396.727997 230.223984,396.776215 229.768921,396.672699 
	C229.562225,396.520966 229.802185,396.215118 229.937347,396.075439 
z"/>
<path fill="#D7BC6C" opacity="1.000000" stroke="none" 
	d="
M183.529678,213.826553 
	C183.290665,213.844177 183.289459,213.598907 183.327515,213.483551 
	C183.713150,213.266800 184.060745,213.165405 184.679047,213.034302 
	C184.556076,213.272720 184.162384,213.540817 183.529678,213.826553 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M106.022736,339.350647 
	C106.139374,339.340820 106.285423,339.583740 106.325790,339.955200 
	C106.164124,339.923584 106.108139,339.763458 106.022736,339.350647 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M255.828720,421.897736 
	C252.988083,421.482239 250.147446,421.066742 246.128113,420.478851 
	C250.231293,419.864685 253.229095,419.415985 256.642334,419.360474 
	C256.648102,420.468323 256.238403,421.183044 255.828720,421.897736 
z"/>
<path fill="#DF9E46" opacity="1.000000" stroke="none" 
	d="
M255.453308,421.923584 
	C256.238403,421.183044 256.648102,420.468323 257.061768,419.419769 
	C257.489136,419.031036 257.912537,418.976166 258.656616,418.924042 
	C259.419556,419.934692 259.861816,420.942596 260.587372,421.965576 
	C260.891785,422.166107 260.912903,422.351532 260.942749,422.817688 
	C260.759552,423.101501 260.567627,423.104614 260.089600,423.140259 
	C257.513184,423.088531 255.222839,423.004272 252.492218,422.706482 
	C252.051926,422.492981 252.070328,422.139832 252.070328,422.139832 
	C253.072861,422.076385 254.075378,422.012909 255.453308,421.923584 
z"/>
<path fill="#BB7730" opacity="1.000000" stroke="none" 
	d="
M251.617676,422.074799 
	C252.070328,422.139832 252.051926,422.492981 252.062149,422.669525 
	C249.246353,422.727295 246.420334,422.608521 243.274521,422.237915 
	C245.691498,421.993958 248.428253,422.001862 251.617676,422.074799 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M240.983734,407.989258 
	C241.865448,408.038300 242.743378,408.080353 243.439667,408.208923 
	C242.494156,408.187103 241.730316,408.078735 240.966476,407.970367 
	C240.966476,407.970367 240.979965,407.982208 240.983734,407.989258 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M240.920425,409.481628 
	C240.217010,409.660919 239.493240,409.809326 238.397461,409.999329 
	C238.022049,409.862610 238.018692,409.684296 237.993591,409.239380 
	C238.725159,409.016724 239.478470,409.060669 240.582275,409.244568 
	C240.932770,409.384460 240.900070,409.450775 240.920425,409.481628 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M241.044342,408.243652 
	C241.730316,408.078735 242.494156,408.187103 243.590576,408.498627 
	C243.923172,408.701813 243.927429,408.859253 243.963715,408.929626 
	C244.000000,409.000000 243.998627,409.002167 243.998627,409.002136 
	C243.358047,408.974609 242.717484,408.947052 241.775452,408.941345 
	C241.356766,408.814484 241.239502,408.665710 241.044342,408.243652 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M237.992401,392.009521 
	C238.383652,392.159912 238.774902,392.310272 239.166168,392.460632 
	C238.920334,392.596191 238.674500,392.731781 238.214783,392.933777 
	C238.000916,393.000183 238.015488,393.016602 238.034271,392.761078 
	C238.036667,392.335693 238.020264,392.165833 238.001740,391.998169 
	C237.999649,392.000397 237.992401,392.009521 237.992401,392.009521 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M244.001740,408.998596 
	C244.277664,409.105896 244.551849,409.214600 244.826050,409.323303 
	C244.617966,409.300385 244.409866,409.277496 244.100204,409.128387 
	C243.998627,409.002167 244.000000,409.000000 244.001740,408.998596 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M233.674072,389.974731 
	C233.450943,390.120697 232.868866,390.257782 232.082092,390.189026 
	C232.356644,389.977417 232.835861,389.971588 233.674072,389.974731 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M237.197052,393.934937 
	C236.973633,394.058929 236.744751,393.791138 236.776550,393.511292 
	C236.808334,393.231415 237.005066,392.994843 237.257690,393.000031 
	C237.678696,393.009033 237.847092,393.012817 238.015488,393.016602 
	C238.015488,393.016602 238.000916,393.000183 237.994812,392.991028 
	C237.799286,393.258240 237.609879,393.534576 237.197052,393.934937 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M237.043167,392.042175 
	C237.008926,391.788544 236.974686,391.534943 236.951065,390.897217 
	C237.247208,390.813416 237.532745,391.113708 237.905334,391.711761 
	C237.992401,392.009521 237.999649,392.000397 237.747238,391.999237 
	C237.325302,391.994019 237.155762,391.989990 237.000244,391.999969 
	C237.014282,392.014008 237.043167,392.042175 237.043167,392.042175 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M231.027222,388.968933 
	C230.890488,388.930176 230.769241,388.576843 230.833908,388.124115 
	C231.027451,388.234619 231.035080,388.444458 231.027222,388.968933 
z"/>
<path fill="#EAC461" opacity="1.000000" stroke="none" 
	d="
M306.632629,257.916809 
	C308.598816,259.304565 310.346161,260.455536 311.776886,261.916534 
	C313.034363,263.200623 313.937347,264.831909 314.996368,266.696381 
	C314.436005,268.439728 313.876190,269.796570 313.290100,271.217041 
	C308.788544,265.829407 304.321167,260.482635 299.853760,255.135880 
	C299.355072,255.309814 298.856384,255.483749 298.357697,255.657684 
	C300.997192,256.376709 303.636688,257.095734 306.632629,257.916809 
z"/>
<path fill="#EAC461" opacity="1.000000" stroke="none" 
	d="
M260.956421,261.935425 
	C260.910522,261.885223 261.197540,261.663452 261.197540,261.663452 
	C261.197540,261.663452 261.237976,261.947113 261.169678,262.012939 
	C261.101379,262.078796 261.002289,261.985626 260.956421,261.935425 
z"/>
<path fill="#D8C379" opacity="1.000000" stroke="none" 
	d="
M264.693115,117.642349 
	C261.915192,110.987160 261.190308,110.831490 253.684311,114.921783 
	C253.111450,111.191849 255.055710,109.166496 259.479584,108.884796 
	C264.059937,108.593117 267.040833,111.582497 267.099060,116.526024 
	C267.154541,121.234810 263.088318,124.830261 258.374298,124.240692 
	C254.539154,123.761040 252.647858,121.066521 253.560883,116.408600 
	C256.832916,121.228790 260.416473,122.993996 264.693115,117.642349 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M122.823631,356.032410 
	C122.967186,356.223206 122.834732,356.459961 122.451225,356.759521 
	C122.198761,356.526459 122.314575,356.278473 122.823631,356.032410 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M131.757141,365.666077 
	C132.274719,366.033569 132.497986,366.558960 132.875351,367.320374 
	C133.038284,368.077087 133.047119,368.597809 132.699448,369.565186 
	C131.882492,370.007507 131.422012,370.003082 130.961548,369.998688 
	C130.961548,369.998688 131.001068,369.978912 131.008453,369.998932 
	C130.603729,369.027252 130.191620,368.035583 129.839874,366.581451 
	C129.900208,366.119019 130.128540,365.835724 130.128540,365.835754 
	C130.573288,365.831818 131.018051,365.827881 131.757141,365.666077 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M135.014221,354.987915 
	C134.139191,354.820007 133.249847,354.665741 132.561188,354.222992 
	C133.522781,353.966217 134.283661,353.997986 135.044556,354.029724 
	C135.044556,354.029724 135.011169,354.006042 134.996979,354.255920 
	C134.979248,354.680389 134.975677,354.855011 134.972122,355.029602 
	C134.972122,355.029602 134.999924,355.001587 135.014221,354.987915 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M134.883575,353.726013 
	C134.283661,353.997986 133.522781,353.966217 132.381226,353.932068 
	C132.000580,353.929657 132.007339,353.471802 132.011688,353.242981 
	C132.918228,353.150177 133.820419,353.286255 134.883575,353.726013 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M129.786713,366.261230 
	C129.306137,366.125946 128.939072,365.848450 128.572006,365.570984 
	C128.978729,365.542175 129.385452,365.513336 129.960358,365.660126 
	C130.128540,365.835724 129.900208,366.119019 129.786713,366.261230 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M135.540070,359.033722 
	C135.404037,359.338654 134.977692,359.680298 134.223877,359.991302 
	C134.347519,359.663910 134.798630,359.367188 135.540070,359.033722 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M144.250839,380.667694 
	C144.667633,381.995667 144.859818,383.040009 144.779785,384.252808 
	C143.314148,383.947021 142.120712,383.472778 140.968307,382.993774 
	C141.009338,382.988983 141.020355,383.070831 140.927078,382.892761 
	C140.546219,382.795349 140.258636,382.875977 139.589752,382.997131 
	C138.953125,383.024506 138.697830,383.011322 138.442551,382.998169 
	C138.206741,383.158295 137.947235,383.222443 137.461670,383.192352 
	C136.766968,382.694336 136.274597,382.194611 135.550217,381.256775 
	C135.001160,380.859741 134.684113,380.900879 134.367065,380.941986 
	C134.138962,380.950073 133.910843,380.958130 133.340805,380.983978 
	C132.716217,380.523224 132.433578,380.044678 132.119415,379.240906 
	C132.087906,378.915680 132.223022,378.785767 132.572601,378.771881 
	C133.586212,378.826477 134.250244,378.894958 135.294937,378.828705 
	C136.464859,378.853973 137.254105,379.014008 138.371826,379.307251 
	C139.832703,379.779266 140.965134,380.118042 142.097549,380.456818 
	C142.740448,380.432556 143.383331,380.408295 144.250839,380.667694 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M134.485489,372.903564 
	C135.625183,373.861908 136.523361,374.874878 137.075073,376.445892 
	C136.580170,377.111237 136.431702,377.218536 136.013397,377.279205 
	C135.379105,377.187286 135.014679,377.141998 134.557983,377.119751 
	C134.465714,377.142792 134.370697,376.978027 134.119522,377.118408 
	C133.318146,377.336761 132.767960,377.414734 132.226685,377.815948 
	C132.231400,378.354706 132.227219,378.570221 132.223022,378.785767 
	C132.223022,378.785767 132.087906,378.915680 132.019577,378.981262 
	C129.033386,376.983429 129.494003,375.760162 134.485489,372.903564 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M131.062866,370.271118 
	C131.422012,370.003082 131.882492,370.007507 132.685272,369.954071 
	C133.162781,370.443207 133.297958,370.990204 133.564911,372.070343 
	C132.543060,371.420502 131.853622,370.982056 131.062866,370.271118 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M132.945282,382.535126 
	C132.945282,382.535126 132.830704,382.783142 132.915100,382.891602 
	C132.999496,383.000031 133.003693,382.993378 133.003693,382.993378 
	C132.643921,382.888275 132.284149,382.783173 131.648468,382.621521 
	C131.033432,382.225098 130.694260,381.885223 130.501984,381.365417 
	C130.852951,381.187561 131.057022,381.189636 131.261108,381.191742 
	C131.261108,381.191742 131.421097,381.210419 131.610504,381.444153 
	C132.181702,381.963623 132.563492,382.249359 132.945282,382.535126 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M132.964828,382.151093 
	C132.563492,382.249359 132.181702,381.963623 131.622849,381.364563 
	C131.704956,381.037964 131.964127,381.024719 132.612518,381.004852 
	C132.995956,381.254486 132.990158,381.510773 132.964828,382.151093 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M131.117218,381.050751 
	C131.057022,381.189636 130.852951,381.187561 130.343063,381.178894 
	C130.349274,381.084808 130.661301,380.997284 131.117218,381.050751 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M214.821060,385.538452 
	C214.262939,385.261902 213.704819,384.985352 212.886688,384.538513 
	C212.396881,384.134033 212.154327,383.913818 211.756378,383.507751 
	C211.613708,383.307861 211.813660,383.163086 212.132812,383.035278 
	C212.614822,382.306061 212.777679,381.704620 212.940536,381.103210 
	C214.637329,379.592834 216.334137,378.082458 218.085815,376.187439 
	C218.906586,376.181610 219.672470,376.560455 220.718323,377.109070 
	C221.582764,377.987244 222.167221,378.695648 222.884811,379.924469 
	C223.013916,380.631714 223.009888,380.818604 223.006088,381.254822 
	C223.006317,381.504150 223.021774,382.002594 222.673355,382.031006 
	C221.537643,383.003387 220.750366,383.947327 219.639359,384.861267 
	C217.817444,385.066986 216.319244,385.302734 214.821060,385.538452 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M219.963089,384.891296 
	C220.750366,383.947327 221.537643,383.003387 222.906250,382.303040 
	C224.869995,383.212036 226.252441,383.877441 227.634888,384.542847 
	C227.634888,384.542847 227.849380,384.397827 227.966705,384.449341 
	C228.065018,386.285309 228.311768,388.154266 225.480179,388.039062 
	C225.023697,388.020477 224.505096,389.528717 223.991486,390.649353 
	C223.797119,390.975281 223.626495,390.982971 223.199921,391.003662 
	C222.630814,390.888489 222.317642,390.760345 221.787720,390.497009 
	C221.313583,390.122284 221.056183,389.882751 221.015930,389.306946 
	C221.773422,387.921204 222.313766,386.871674 222.854111,385.822174 
	C221.890442,385.511871 220.926773,385.201569 219.963089,384.891296 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M227.511642,384.250793 
	C226.252441,383.877441 224.869995,383.212036 223.254669,382.274597 
	C223.021774,382.002594 223.006317,381.504150 223.265076,381.250000 
	C223.696625,380.992706 223.869385,380.989532 224.042160,380.986359 
	C225.157562,381.977142 226.272980,382.967957 227.511642,384.250793 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M223.949127,380.743652 
	C223.869385,380.989532 223.696625,380.992706 223.264862,381.000671 
	C223.009888,380.818604 223.013916,380.631714 223.035217,380.163788 
	C223.320374,380.088806 223.588242,380.294891 223.949127,380.743652 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M142.999649,388.549988 
	C142.999649,388.549988 142.949799,388.616455 142.926025,388.616211 
	C143.129990,388.832245 143.357742,389.048523 143.790955,389.688782 
	C143.286209,389.777069 142.576004,389.441437 141.346970,388.807800 
	C140.910492,388.390594 140.851822,388.241455 140.996201,388.082703 
	C141.893402,388.252075 142.446518,388.401031 142.999649,388.549988 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M134.450836,380.925354 
	C134.684113,380.900879 135.001160,380.859741 135.168182,380.895630 
	C135.018158,380.972626 134.534622,380.908691 134.450836,380.925354 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M132.995483,383.002014 
	C132.991455,383.004028 133.112930,383.048920 133.058319,383.021149 
	C133.003693,382.993378 132.999496,383.000031 132.995483,383.002014 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M138.662537,383.236572 
	C138.697830,383.011322 138.953125,383.024506 139.848511,383.061279 
	C140.665817,383.080200 140.843079,383.075531 141.020355,383.070831 
	C141.020355,383.070831 141.009338,382.988983 141.237106,383.384491 
	C142.292343,384.250092 143.119797,384.720215 143.947266,385.190308 
	C143.646667,385.371307 143.346069,385.552307 142.324814,385.454712 
	C141.405151,385.098358 141.206161,385.020630 141.007187,384.942932 
	C140.298965,384.453613 139.590729,383.964264 138.662537,383.236572 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M142.865631,388.225220 
	C142.446518,388.401031 141.893402,388.252075 140.910004,388.075439 
	C140.599518,387.885559 140.719330,387.723358 140.909576,387.266968 
	C141.563873,387.281982 142.147736,387.591217 142.865631,388.225220 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M140.791840,386.038086 
	C141.066986,386.143036 141.114105,386.321014 141.089172,386.765564 
	C140.866013,386.725128 140.714920,386.418121 140.791840,386.038086 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M141.058365,385.216431 
	C141.206161,385.020630 141.405151,385.098358 141.799026,385.430603 
	C141.776154,385.787567 141.549500,385.863495 141.143433,385.971069 
	C141.018463,385.849548 141.063995,385.669739 141.058365,385.216431 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M213.699127,374.948181 
	C214.310822,375.378693 214.609070,375.771484 215.084991,376.354675 
	C214.278915,376.144714 213.295181,375.744415 212.228943,375.023010 
	C212.559509,374.771423 212.972595,374.840942 213.699127,374.948181 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M212.751007,381.296143 
	C212.777679,381.704620 212.614822,382.306061 212.228928,382.957855 
	C212.191101,382.501862 212.376297,381.995453 212.751007,381.296143 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M210.777130,382.759705 
	C210.644119,382.794525 210.317581,382.645538 209.816650,382.284180 
	C209.956024,382.239838 210.269806,382.407867 210.777130,382.759705 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M136.964890,363.038208 
	C138.171127,363.140045 139.377380,363.241882 140.841034,363.606262 
	C141.098450,363.868774 140.950394,364.052032 140.875061,364.145508 
	C139.698288,363.984741 138.596832,363.730469 137.244781,363.241516 
	C136.994186,363.006897 136.964890,363.038208 136.964890,363.038208 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M136.984711,359.199768 
	C137.739090,359.018829 138.501434,358.975983 139.646149,358.923889 
	C139.871002,359.193481 139.687195,359.453796 138.935791,359.813232 
	C137.915848,359.824768 137.437180,359.718597 136.958527,359.612427 
	C136.958527,359.612427 136.992706,359.337860 136.984711,359.199768 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M137.994843,365.479858 
	C138.015137,365.467865 137.974533,365.491821 137.994843,365.479858 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M137.008636,362.991272 
	C136.633179,362.766174 136.243286,362.556702 135.853409,362.347229 
	C136.237289,362.396332 136.621155,362.445435 136.984955,362.766357 
	C136.964890,363.038208 136.994186,363.006897 137.008636,362.991272 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M136.948303,360.056091 
	C137.437180,359.718597 137.915848,359.824768 138.762802,359.978271 
	C139.188751,360.199402 139.246399,360.373138 139.399200,360.804565 
	C138.642258,360.874756 137.790176,360.687256 136.948303,360.056091 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M142.727234,365.447937 
	C143.225922,365.243774 143.505402,365.328308 143.883591,365.978668 
	C144.010910,366.621307 144.005447,366.810638 144.000000,367.000000 
	C143.234451,367.078064 142.468918,367.156097 141.703369,367.234131 
	C141.982925,366.697418 142.262497,366.160736 142.727234,365.447937 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M142.282959,373.064636 
	C141.863602,372.925568 141.752029,372.747040 141.551300,372.265503 
	C142.025101,372.021057 142.588074,372.079529 143.151031,372.138000 
	C142.964264,372.433746 142.777496,372.729431 142.282959,373.064636 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M173.104309,365.569885 
	C173.054245,365.624756 172.882812,365.426880 172.882812,365.426880 
	C172.882812,365.426880 173.154388,365.515015 173.104309,365.569885 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M142.162552,380.055511 
	C140.965134,380.118042 139.832703,379.779266 138.338654,379.052460 
	C137.920197,378.504059 137.863373,378.343719 137.965210,377.926147 
	C138.194427,377.480225 138.265015,377.291504 138.335587,377.102783 
	C139.632919,377.953247 140.930237,378.803711 142.162552,380.055511 
z"/>
<path fill="#877349" opacity="1.000000" stroke="none" 
	d="
M136.956879,376.850952 
	C137.450989,376.776978 137.719986,376.843231 138.163864,376.999756 
	C138.265015,377.291504 138.194427,377.480225 137.683014,377.841003 
	C136.922531,377.783997 136.602890,377.554932 136.283249,377.325867 
	C136.431702,377.218536 136.580170,377.111237 136.956879,376.850952 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M144.117386,385.251648 
	C143.119797,384.720215 142.292343,384.250092 141.196075,383.389282 
	C142.120712,383.472778 143.314148,383.947021 144.823273,384.655090 
	C145.138977,384.888885 145.147446,384.847321 145.125519,384.845825 
	C145.325317,385.024506 145.547043,385.204681 145.906281,385.792786 
	C145.458374,385.904816 144.872940,385.608887 144.117386,385.251648 
z"/>
<path fill="#170A03" opacity="1.000000" stroke="none" 
	d="
M201.749878,386.063049 
	C201.346039,386.218628 200.960632,386.101471 200.292984,385.871582 
	C200.391998,385.422729 200.773254,385.086670 201.154526,384.750610 
	C201.359131,385.097168 201.563736,385.443726 201.749878,386.063049 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M135.244232,355.001648 
	C134.975677,354.855011 134.979248,354.680389 134.980423,354.243896 
	C135.141159,353.943024 135.304276,353.904022 135.715057,353.906372 
	C135.962723,353.947723 136.005051,354.393738 136.025818,354.616241 
	C135.869827,354.883728 135.693100,354.928711 135.244232,355.001648 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M144.263428,366.961487 
	C144.005447,366.810638 144.010910,366.621307 144.029312,366.148071 
	C144.465042,365.937531 144.887833,366.010834 145.624542,366.156250 
	C145.467926,366.459869 144.997391,366.691437 144.263428,366.961487 
z"/>
<path fill="#835B2F" opacity="1.000000" stroke="none" 
	d="
M236.708740,393.348694 
	C236.580963,392.986206 236.552750,392.506439 236.783859,392.034424 
	C237.043167,392.042175 237.014282,392.014008 236.998688,392.252441 
	C236.990402,392.658875 236.997726,392.826843 237.005066,392.994843 
	C237.005066,392.994843 236.808334,393.231415 236.708740,393.348694 
z"/>
<path fill="#1F0D04" opacity="1.000000" stroke="none" 
	d="
M227.915894,390.873779 
	C228.002792,390.979858 227.530884,390.908386 227.310760,390.816345 
	C227.353561,390.559601 227.599670,390.574066 227.915894,390.873779 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M219.639359,384.861267 
	C220.926773,385.201569 221.890442,385.511871 222.854111,385.822174 
	C222.313766,386.871674 221.773422,387.921204 220.876648,389.082977 
	C219.874985,389.065369 219.229752,388.935486 218.272797,388.923340 
	C217.903397,388.691437 217.845688,388.341858 217.531769,387.838440 
	C216.705994,387.400269 216.136444,387.115936 215.256287,386.938263 
	C215.023758,386.827942 215.125839,386.615662 215.169449,386.391998 
	C215.190002,386.286621 215.098282,386.159393 214.939270,385.790253 
	C216.319244,385.302734 217.817444,385.066986 219.639359,384.861267 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M238.156952,418.283386 
	C239.007309,418.016479 240.068832,418.041351 241.540802,418.280029 
	C241.951233,418.493805 241.947449,418.917053 241.947449,418.917053 
	C240.754349,418.803101 239.561234,418.689148 238.156952,418.283386 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M242.223709,418.903931 
	C241.947449,418.917053 241.951233,418.493805 241.938995,418.283264 
	C242.112137,418.046875 242.297546,418.021057 242.763077,418.014709 
	C242.862122,418.319702 242.681046,418.605225 242.223709,418.903931 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M237.257690,393.000031 
	C236.997726,392.826843 236.990402,392.658875 236.984650,392.238403 
	C237.155762,391.989990 237.325302,391.994019 237.749329,391.997009 
	C238.020264,392.165833 238.036667,392.335693 238.034271,392.761078 
	C237.847092,393.012817 237.678696,393.009033 237.257690,393.000031 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M136.013397,377.279205 
	C136.602890,377.554932 136.922531,377.783997 137.524368,378.098206 
	C137.863373,378.343719 137.920197,378.504059 138.010193,378.919250 
	C137.254105,379.014008 136.464859,378.853973 135.229248,378.520905 
	C134.738663,377.930817 134.694443,377.513794 134.650238,377.096741 
	C135.014679,377.141998 135.379105,377.187286 136.013397,377.279205 
z"/>
<path fill="#CBB169" opacity="1.000000" stroke="none" 
	d="
M134.557968,377.119751 
	C134.694443,377.513794 134.738663,377.930817 134.848572,378.655670 
	C134.250244,378.894958 133.586212,378.826477 132.572601,378.771881 
	C132.227219,378.570221 132.231400,378.354706 132.498428,377.883636 
	C133.297745,377.411407 133.834213,377.194702 134.370697,376.978027 
	C134.370697,376.978027 134.465714,377.142792 134.557968,377.119751 
z"/>
<path fill="#2C1105" opacity="1.000000" stroke="none" 
	d="
M140.927078,382.892761 
	C140.843079,383.075531 140.665817,383.080200 140.229813,383.020721 
	C140.258636,382.875977 140.546219,382.795349 140.927078,382.892761 
z"/>
<path fill="#582D08" opacity="1.000000" stroke="none" 
	d="
M134.119507,377.118378 
	C133.834213,377.194702 133.297745,377.411407 132.489532,377.560394 
	C132.767960,377.414734 133.318146,377.336761 134.119507,377.118378 
z"/>


    
</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
